import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../services/api";
import MUIDataTable from "../../../components/MUIGrid";
import { FaSearch } from "react-icons/fa";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { Modal } from "../../../components";
import { IoEyeSharp } from "react-icons/io5";
import { ImNewTab } from "react-icons/im";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import {
  Button as MUIButton,
  TablePagination,
  LinearProgress,
  Box,
} from "@mui/material";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
import DateRangeComp from "../../../components/DateRangePicker";
import LogsModal from "./LogsModal";

export default function UserLogs() {
  const [isRecords, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [logsData, setLogsData] = useState([]);
  const [isDataModal, setDataModal] = useState(false);
  const [users, setUsers] = useState([]);
  dayjs.extend(duration);

  const [sortingModel, setSortingModel] = React.useState({
    sortField: null,
    sortOrder: null,
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });

  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const cancelButtonRef = useRef(false);
  const currentDate = new Date();
  const initialFilters = {
    start_date:
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0,
        0
      )
        .toISOString()
        .slice(0, 23) + "Z",
    end_date: new Date().toISOString().slice(0, 23) + "Z",
  };

  const [filters, setFilters] = React.useState(initialFilters);

  const handleSortModelChange = (params) => {
    setSortingModel({
      sortField: params[0]?.field,
      sortOrder: params[0]?.sort ? params[0]?.sort.toUpperCase() : "default",
    });

    fetchListing({
      filters: queryOptions,
      page: 1,
      perPage: paginationModel.pageSize,
      sortField: params[0]?.field,
      sortOrder: params[0]?.sort ? params[0]?.sort.toUpperCase() : "default",
    });
  };

  const getSessionLogInfo = async (session_id, index) => {
    setIsLoading(true);
    let payload = {
      user_session_log_id: session_id,
    };
    try {
      const response = await api.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/report/user_session_api`,
        payload
      );
      if (response.status === 200 || response.status === 201) {
        setLogsData((prevData) => {
          const updatedRecords = [...prevData]; // Create a shallow copy of the records array
          updatedRecords[index] = {
            ...updatedRecords[index], // Copy the specific record at the given index
            session_id_log: response.data.length, // Update the specific key (session_id_log)
          };

          return updatedRecords;
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.error(`Error fetching users data: ${error}`);
    }
    setIsLoading(false);
  };

  const fetchListing = async (filter) => {
    if (!filter.loaderTerminated) {
      setIsLoading(true);
    }
    let payload = {
      ...filter,
      start_date: filters?.start_date,
      end_date: filters?.end_date,
    };

    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const response = await api.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/report/user_session_log`,
        payload
      );
      if (response.status === 200 || response.status === 201) {
        setRecords(response.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.error(`Error fetching users data: ${error}`);
    } finally {
      if (!filter.loaderTerminated) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      fetchListing({
        ...sortingModel,
        filters: queryOptions,
        page: 1,
        perPage: paginationModel.pageSize,
        loaderTerminated: true,
      }),
    ])
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    fetchListing({
      ...sortingModel,
      filters: queryOptions,
      page: +page + 1,
      perPage: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    fetchListing({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      perPage: newPageSize,
    });
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="flex items-center justify-between">
        <div className="flex">
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            ref={setFilterButtonEl}
          />

          <MUIButton
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            Apply filter
          </MUIButton>
        </div>
        <div className="flex items-center">
          <TablePagination
            color="primary"
            shape="rounded"
            size="medium"
            showFirstButton
            showLastButton
            count={
              isRecords?.totalRecords
                ? isRecords?.totalRecords
                : isRecords?.dataRows?.length || 0
            }
            page={paginationModel.page - 1 || 0}
            onPageChange={(e, page) => handleTopPageChange(page)}
            rowsPerPage={paginationModel.pageSize || 100}
            onRowsPerPageChange={(e) => handleTopPageSizeChange(e.target.value)}
            component="div"
            rowsPerPageOptions={[
              25,
              50,
              75,
              100,
              250,
              500,
              1000,
              1500,
              2000,
              {
                label: "All",
                value: isRecords?.totalRecords
                  ? isRecords?.totalRecords
                  : isRecords?.data?.length || 0,
              },
            ]}
          />
        </div>
      </GridToolbarContainer>
    );
  }

  const columnDefs = [
    {
      headerName: "Sr#",
      field: "counter",
      width: 10,
      filterable: false,
      sortable: false,
    },
    {
      field: "u.id",
      headerName: "Name",
      minWidth: 120,
      type: "singleSelect",
      sortable: false,
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.name,
      valueOptions: users,
      valueGetter: (params) => params.row["u.name"],
      valueFormatter: (params) => params.value,
    },
    {
      headerName: "Status",
      field: "u.is_active",
      maxWidth: 120,
      filterable: true,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: [
        { value: 1, label: "Active" },
        { value: 0, label: "In Active" },
      ],
      renderCell: (params) => params.value?.["u.is_active"],
      valueGetter: (params) => params.row?.["u.is_active"],
      valueFormatter: (params) => params.value,
      sortable: false,
    },
    {
      headerName: "Role",
      field: "r.id",
      minWidth: 150,
      type: "singleSelect",
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.name,
      valueOptions: roles,
      renderCell: (params) => params.row["r.name"],
      sortable: false,
    },
    {
      headerName: "Total Logins",
      field: "total_logins",
      minWidth: 120,
      align: "center",
      type: "number",
      sortable: false,
      renderCell: (params) =>
        params?.row.total_logins ? (
          <div className="flex items-center justify-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row.total_logins}
          </div>
        ) : (
          <div className="flex items-center justify-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </div>
        ),
    },
    {
      headerName: "Avg Session Duration",
      field: "avg_session_duration",
      filterable: false,
      minWidth: 230,
      renderCell: (params) =>
        params?.row.avg_session_duration ? (
          <span className=" text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-indigo-100 text-indigo-800">
            {formatTimeToHMS(params?.row.avg_session_duration)}
          </span>
        ) : null,
    },
    {
      headerName: "Total Session Duration",
      field: "total_session_duration",
      minWidth: 230,
      filterable: false,
      renderCell: (params) =>
        params?.row.total_session_duration ? (
          <span className=" text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-indigo-100 text-indigo-800">
            {formatTimeToHMS(params?.row.total_session_duration)}
          </span>
        ) : null,
    },
    {
      headerName: "Show Logs",
      field: "",
      filterable: false,
      align: "center",
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <div class="flex justify-center items-center h-screen">
          <button
            onClick={(e) => openDataModal(params?.row?.userSessions)}
            className="w-full inline-flex items-center justify-center space-x-2"
          >
            <IoEyeSharp className="w-5 h-5" />
          </button>
        </div>
      ),
    },
    {
      headerName: "Performance %",
      field: "pp",
      flex: 1,
      minWidth: 130,
      filterable: false,
      renderCell: ({ row: { pp } }) => {
        const completedPercentage = Math.min(Math.max(Math.round(pp), 0), 100);
        return (
          <Box width="100%">
            <LinearProgress variant="determinate" value={completedPercentage} />
            {completedPercentage || 0}%
          </Box>
        );
      },
    },
    {
      headerName: "Total Tasks",
      field: "total_tasks",
      minWidth: 120,
      align: "center",
      type: "number",
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params?.row.total_tasks ? (
          <div className="flex items-center justify-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row.total_tasks}
          </div>
        ) : (
          <div className="flex items-center justify-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </div>
        ),
    },
    {
      headerName: "Go To Performance Report",
      field: "back_to",
      filterable: false,
      align: "center",
      minWidth: 180,
      sortable: false,
      headerAlign: "center",
      renderCell: ({ row }) => (
        <div className="flex justify-center items-center h-screen">
          {[8, 10, 3].includes(row["r.id"]) ? (
            <button
              onClick={() => handleOpenReport(row["r.id"], row["u.id"])}
              title="Go To Performance Report"
              className="w-full inline-flex items-center justify-center space-x-2"
            >
              <ImNewTab className="w-5 h-5" />
            </button>
          ) : (
            "N/A"
          )}
        </div>
      ),
    },
  ];

  const dataRows = (isRecords?.records || [])?.map((record, index) => {
    const counter = index + 1;
    const {
      "u.id": userId,
      "u.name": userName,
      "u.is_active": userIsActive,
      "r.id": roleId,
      "r.name": roleName,
      total_logins,
      avg_session_duration,
      total_session_duration,
      user_sessions,
      pp,
      total_tasks,
    } = record;

    const sessionsSummary = user_sessions?.map((session) => ({
      login_time: session.login_time,
      logout_time: session.logout_time,
      session_duration: session.session_duration,
      session_id: session.session_id,
    }));

    return {
      counter,
      "u.id": userId,
      "u.name": userName,
      "u.is_active": userIsActive === 1 ? "Active" : "In Active",
      "r.id": roleId,
      "r.name": roleName,
      total_logins,
      avg_session_duration,
      total_session_duration,
      userSessions: sessionsSummary,
      pp,
      total_tasks,
    };
  });

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    fetchListing({
      ...sortingModel,
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };

  useEffect(() => {
    api
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/roles`)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    fetchListing({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      perPage: paginationModel.pageSize,
    });
  };

  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      fetchListing({
        ...sortingModel,
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatTimeToHMS(timeStr) {
    if (timeStr === "00:00:00" || timeStr === "00:00:00.0000") {
      return "N/A";
    }

    const hasMilliseconds = timeStr.includes(".");

    let parsedTime;

    if (hasMilliseconds) {
      const regex = /^(\d{2}):(\d{2}):(\d{2})\.(\d{4})$/;
      const match = timeStr.match(regex);

      if (match) {
        // Extract hours, minutes, seconds, and milliseconds
        const hours = parseInt(match[1], 10);
        const minutes = parseInt(match[2], 10);
        const seconds = parseInt(match[3], 10);
        const milliseconds = parseInt(match[4], 10);
        parsedTime = dayjs.duration({
          hours,
          minutes,
          seconds,
          milliseconds,
        });
      }
    } else {
      const [hours, minutes, seconds] = timeStr.split(":").map(Number);
      parsedTime = dayjs.duration({
        hours,
        minutes,
        seconds,
      });
    }

    // Build the formatted string, omitting zero values
    const parts = [];

    // Include hours if greater than 0
    if (parsedTime.hours() > 0) {
      parts.push(`${parsedTime.hours()} hours`);
    }

    // Include minutes if greater than 0
    if (parsedTime.minutes() > 0) {
      parts.push(`${parsedTime.minutes()} minutes`);
    }

    // Include seconds if greater than 0
    if (parsedTime.seconds() > 0) {
      parts.push(`${parsedTime.seconds()} seconds`);
    }
    if (parts.length === 0) {
      return "N/A";
    }

    return parts.join(", ");
  }

  const handleDataModal = () => {
    setDataModal(!isDataModal);
  };

  const openDataModal = async (records) => {
    setLogsData(records);
    const sessionsSummary = records?.map((record) => ({
      ...record,
      session_id_log: null,
    }));
    setLogsData(sessionsSummary);
    setDataModal(true);
    setIsLoading(false);
  };

  const handleOpenReport = (roleId, userId) => {
    const startDate = filters.start_date;
    const endDate = filters.end_date;
    const reportData = { startDate, endDate };
    localStorage.setItem("user_activity_report", JSON.stringify(reportData));
    const openUrl = ["8", "10"].includes(String(roleId))
      ? `/reports/leads-performance-report?user_id=${userId}`
      : `/reports/performance-report?user_id=${userId}`;

    const url = new URL(openUrl, window.location.origin);
    const tabName = `reportTab`;

    let existingTab = window.open("", tabName);

    if (!existingTab || existingTab.closed) {
      existingTab = window.open(url, tabName);
    } else {
      existingTab.location.href = url;
      existingTab.focus();
    }
  };

  useEffect(() => {
    const filters = {
      groupOp: "AND",
      rules: [{ field: "is_active", op: "eq", data: 1 }],
    };
    api
      .post(`${process.env.REACT_APP_PUBLIC_API}/api/users/users_report`, {
        filters,
        page: 1,
        perPage: 1000,
      })
      .then((response) => {
        const capitalizedUsers = response.data?.records.map((user) => {
          return {
            ...user,
            name: user.name.replace(/\b\w/g, (c) => c.toUpperCase()),
          };
        });
        setUsers(capitalizedUsers);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Users Activity Report", link: "" },
        ]}
      />
      <ToastContainer />
      <div className="p-0 flex justify-end items-center w-full">
        <div className="flex items-center">
          <DateRangeComp
            inputClassName="!font-medium w-[220px] text-center bg-[#e9ecef] text-sm rounded-l !py-1 !px-2 border border-[#ced4da] !mb-0 rounded-0"
            pickerClassName="!left-1/2 !top-8 -translate-x-[75%]"
            dateRange
            onChange={(e) => {
              const endDate = new Date(e.selection.endDate);
              endDate.setHours(23, 59, 59, 59);
              const formattedEndDate = endDate.toISOString().slice(0, 23) + "Z";

              const startDate = new Date(e.selection.startDate);
              startDate.setHours(0, 0, 0, 0);
              const formattedStartDate =
                startDate.toISOString().slice(0, 23) + "Z";
              setFilters({
                ...filters,
                start_date: formattedStartDate,
                end_date: formattedEndDate,
              });
            }}
            startDate={filters.start_date}
            endDate={filters.end_date}
          />
          <button
            variant="btn_cancel"
            className="h-[31px] !bg-[#042A42]  rounded-r px-3 py-1.5 text-white"
            onClick={() =>
              fetchListing({
                ...sortingModel,
                filters: queryOptions,
                page: 1,
                perPage: paginationModel.pageSize,
              })
            }
          >
            <FaSearch size={14} />
          </button>
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={dataRows}
          CustomToolbar={CustomToolbar}
          height="75vh"
          searchable="No"
          showCount="No"
          totalItems={isRecords?.totalRecords}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
          onFilterModelChange={onFilterChange}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          hideFooter="true"
          isLoading={isLoading}
        />
      </div>
      <Modal
        open={!isDataModal}
        handleModal={handleDataModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-4xl"
        title="User Activity Logs"
      >
        <LogsModal
          logsData={logsData}
          getSessionLogInfo={getSessionLogInfo}
          isLoading={isLoading}
        />
      </Modal>
    </div>
  );
}

import React from "react";
import MUIDataTable from "../../components/MUIGrid";

const HoldBacklinksModal = ({ logsData, taskStatusOptions, users }) => {
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const columns = [
    { field: "counter", headerName: "Sr#", width: 5 },
    {
      field: "backlink_domain",
      headerName: "Backlink Domain",
      minWidth: 230,
      renderCell: (params) => {
        return (
          <a
            href={`https://www.${params.row["backlink_domain"]}`}
            className="hover:text-secondary underline"
            target="_blank"
            rel="noreferrer"
          >
            {params.row["backlink_domain"]}
          </a>
        );
      },
    },
    {
      field: "spam_score",
      headerName: "Spam Score",
      width: 100,
      editable: true,
      renderCell: (params) =>
        params?.row.spam_score > 0 ? (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row.spam_score ? params?.row.spam_score : "-"}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      field: "intersect_count",
      headerName: "Intersect Count",
      width: 130,
      editable: true,
      renderCell: (params) =>
        params?.row.intersect_count > 0 ? (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row.intersect_count ? params?.row.intersect_count : "-"}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      field: "ready_status",
      headerName: "Ready Status",
      width: 150,
    },
    {
      field: "type",
      headerName: "Type",
      width: 160,
    },
  ];
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };

  return (
    <MUIDataTable
      columnDefs={columns}
      items={(logsData?.backlinks?.length ? logsData?.backlinks : [])?.map(
        (record, index) => {
          const {
            backlink_domain,
            spam_score,
            intersect_count,
            ready_status,
            type,
          } = record;

          return {
            counter: index + 1,
            backlink_domain,
            spam_score,
            intersect_count,
            ready_status,
            type,
          };
        }
      )}
      totalItems={logsData?.length}
      height="60vh"
      pagination="No"
      paginationModel={paginationModel}
      searchable="No"
      showCount="No"
      sortingMode="client"
      paginationMode="client"
      toolbar="No"
      onPaginationModelChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      sx={{
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader--sorted": {
          border: "none",
        },
      }}
    />
  );
};

export default HoldBacklinksModal;

import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import Loading from "../../components/Loader/Loading";
import MUIDataTable from "../../components/MUIGrid";
import { FaSearch } from "react-icons/fa";
import DateRangeComp from "../../components/DateRangePicker";
import dayjs from "dayjs";
export default function PerformanceReport({ boxHeight }) {
  const [isRecords, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const [queryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });

  const taskStatus = [
    { value: "inReview", label: "in Review" },
    { value: "hold", label: "Hold" },
    { value: "reOpen", label: "Reopen" },
  ];

  const initialFilters = {
    start_date: dayjs().subtract(7, "day").startOf("day").toDate(),
    end_date: new Date().toISOString().slice(0, 23) + "Z",
  };

  const [filters, setFilters] = React.useState(initialFilters);

  function addTaskStatusRule(payload) {
    const taskStatusRule = {
      field: "task_status",
      op: "eq",
      data: "inReview",
    };

    if (
      payload?.filters?.rules.length &&
      !payload?.filters?.rules.some((rule) => rule.field === "task_status")
    ) {
      payload.filters.rules.push(taskStatusRule);
    }

    return payload;
  }

  const fetchListing = async (filter) => {
    setIsLoading(true);
    let payload = {
      ...filter,
      start_date: filters?.start_date,
      end_date: filters?.end_date,
    };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    const updatedPayload = addTaskStatusRule(payload);
    try {
      const response = await api.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/report/user_performance_report`,
        updatedPayload
      );
      setRecords(response.data);
    } catch (error) {
      console.error(`Error fetching  data: ${error}`);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchListing({
      filters: queryOptions,
    });
    get_user();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnDefs = [
    { headerName: "Sr#", field: "counter", width: 200, filterable: false },
    {
      field: "user_id",
      headerName: "User",
      minWidth: 300,
      type: "singleSelect",
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.name,
      valueOptions: users,
      valueGetter: (params) => params.row.user_id,
      valueFormatter: (params) => params.value,
      renderCell: (params) => <>{params.row.name}</>,
    },
    {
      field: "task_status",
      headerName: "Task Status",
      width: 100,
      editable: true,
      filterable: false,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: taskStatus,
      valueFormatter: (params) => params.value,
    },
    {
      field: "total_task_count",
      headerName: "Total Count",
      filterable: false,
      width: 150,
      flex: 1,
    },
    {
      field: "in_process_count",
      headerName: "In Process Count",
      width: 150,
      filterable: false,
      flex: 1,
    },
    {
      field: "in_review_count",
      headerName: "In Review Count",
      filterable: false,
      width: 150,
      flex: 1,
    },
    {
      field: "hold_count",
      headerName: "Hold Count",
      width: 150,
      filterable: false,
      flex: 1,
    },
  ];

  const dataRows = (isRecords || [])?.map((record, index) => {
    const counter = index + 1;
    const records = record;
    const {
      user_id,
      name,
      total_task_count,
      re_open_count,
      in_review_count,
      in_process_count,
      hold_count,
      completed_count,
      cancelled_count,
    } = record;

    return {
      counter,
      records,
      user_id,
      name,
      total_task_count,
      re_open_count,
      in_review_count,
      in_process_count,
      hold_count,
      completed_count,
      cancelled_count,
    };
  });

  const get_user = async () => {
    const filters = {
      groupOp: "AND",
      rules: [
        { field: "is_active", op: "eq", data: 1 },
        { field: "role_id", op: "isAnyOf", data: [3, 8] },
      ],
    };
    try {
      const res = await api.post("/api/users/users_report", {
        filters,
        page: 1,
        perPage: 1000,
      });
      if (res.status === 200) {
        setUsers(res.data?.records);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    task_status: false,
  });
  const handleColumnVisibilityChange = (model) => {
    setColumnVisibilityModel(model);
  };

  return (
    <div className="bg-white my-3 border rounded">
      {isLoading && <Loading />}
      <div className="flex justify-between">
        <div className="p-3 flex items-center space-x-4 text-xl font-semibold capitalize">
          <span>Linkbuilders Performance Report</span>
        </div>
        <div className="p-3 flex flex-row">
          <DateRangeComp
            inputClassName="!font-medium w-[220px] text-center bg-[#e9ecef] text-sm rounded-l !py-1 !px-2 border border-[#ced4da] !mb-0 rounded-0"
            pickerClassName="!left-1/2 !top-8 -translate-x-[75%]"
            dateRange
            onChange={(e) => {
              const endDate = new Date(e.selection.endDate);
              endDate.setHours(23, 59, 59, 59);
              const formattedEndDate = endDate.toISOString().slice(0, 23) + "Z";

              const startDate = new Date(e.selection.startDate);
              startDate.setHours(0, 0, 0, 0);
              const formattedStartDate =
                startDate.toISOString().slice(0, 23) + "Z";
              setFilters({
                ...filters,
                start_date: formattedStartDate,
                end_date: formattedEndDate,
              });
            }}
            startDate={filters.start_date}
            endDate={filters.end_date}
          />
          <button
            variant="btn_cancel"
            className="max-h-[30px] !bg-[#042A42] ml- rounded-r px-3 py-1.5 text-white"
            onClick={() =>
              fetchListing({
                filters: queryOptions,
              })
            }
          >
            <FaSearch size={14} />
          </button>
        </div>
      </div>

      <div className="bg-white my-0 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={dataRows}
          pagination="No"
          searchable="No"
          showCount="No"
          toolbar="No"
          isLoading={isLoading}
          height={boxHeight - 80}
          hideFooter
          columnVisibilityModel={columnVisibilityModel}
          handleColumnVisibilityChange={handleColumnVisibilityChange}
        />
      </div>
    </div>
  );
}

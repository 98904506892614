import React, { useState, useRef } from "react";
import api from "../../services/api";
import Modal from "../common/Modal";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

function ImportUpdateExcelModal({
  setOpen,
  isExcelImport,
  fetchBacklinksData,
}) {
  const [showWarning, setShowWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //const [isValidate, setIsValidate] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [errors, setErrors] = useState({});
  const [excelFile, setExcelFile] = useState();
  const handleModal = () => {
    setOpen(!isExcelImport);
  };
  const cancelButtonRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    if (!excelFile) {
      errors.file = "Please select a file.";
    } else if (
      excelFile.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      errors.file = "Please select a valid Excel file (.xlsx).";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    }

    // Use FileReader to read the file
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const filteredData = jsonData.filter((row) =>
        row.some((cell) => cell !== undefined && cell !== null && cell !== "")
      );
      if (filteredData.length <= 1) {
        setErrorMsg("The file contains no valid data.");
        setIsLoading(false);
        return;
      }

      // Get header row and remove it from data
      const headers = filteredData[0];
      const dataRows = filteredData.slice(1);

      // Map data to objects based on headers
      const mappedData = dataRows.map((row) =>
        headers.reduce((acc, header, index) => {
          acc[header] = row[index] ?? "";
          return acc;
        }, {})
      );
      setIsLoading(true);
      setErrorMsg(null);
      api
        .post(
          `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/update_using_excel`,
          { backlinks: mappedData }
        )
        .then((response) => {
          if (response.status === 200) {
            toast.success("This file imported successfully");
            handleModal();
            fetchBacklinksData({
              page: 1,
              perPage: 100,
              filter: [],
            });
          } else {
            toast.error(
              response.data && typeof response.data === "string"
                ? response.data
                : response.data?.message || "This file couldn't be imported."
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setErrorMsg(
            error?.response.data && typeof error?.response.data === "string"
              ? error?.response.data
              : error?.response.data?.message ||
                  "This file couldn't be imported."
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    reader.readAsArrayBuffer(excelFile);
  };

  const onDrop = React.useCallback((acceptedFiles) => {
    let files = acceptedFiles[0];
    setExcelFile(files);
    setShowWarning(false);
    //setIsValidate(null);
    setErrorMsg(null);
  }, []);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  const saveBlobAsExcelFile = (blobData, type) => {
    const downloadLink = window.URL.createObjectURL(new Blob([blobData]));

    const link = document.createElement("a");
    link.href = downloadLink;
    link.setAttribute(
      "download",
      `${type ? "ahref_backlinks.xlsx" : "backlinks.xlsx"}`
    ); // Set the filename

    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadLink);
  };

  const handleSampleExport = async (type) => {
    setIsLoading(true);
    try {
      const res = await api.get(`/api/backlinks/export_sample_update_excel`, {
        responseType: "blob",
      });
      if (res.status === 200) {
        const blobData = res.data;
        saveBlobAsExcelFile(blobData, type);
        toast.success("Sample export successfully");
      } else {
        toast.error("Sample couldn't be exported successfully");
      }
      setIsLoading(false);
    } catch (err) {
      toast.error("Sample couldn't be exported successfully");
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        open={false}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        handleModalSubmit={handleSubmit}
        className="max-w-[70%]"
        title={"Update With Excel"}
        isLoading={isLoading}
        additionalButtons
        handleSampleExport={(type) => handleSampleExport(type)}
        handleImportCsv={handleSubmit}
        buttonText={"Import"}
      >
        <div className="grid gap-4 dark:text-white">
          <div className="flex flex-col justify-center w-full">
            <div
              className={`!mt-3 w-full flex justify-center border-2 border-dashed rounded-md !p-2 hover:border-yellow-500 focus:outline-none ${
                isDragActive ? "bg-gray-100" : "bg-white dark:bg-dark"
              } ${
                errors?.file
                  ? "border-red-500"
                  : "border-gray-200 dark:border-white/20"
              }`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <label
                className={`flex justify-center w-full h-[130px] px-4 transition appearance-none cursor-pointer`}
                onClick={open}
              >
                {excelFile ? (
                  <span className="flex items-center space-x-2">
                    <div className="flex flex-col text-center">
                      <span className="font-medium text-gray-600">
                        File Name: {excelFile?.name}
                      </span>
                      <span className="text-gray-600">
                        Size:{" "}
                        {excelFile?.size > 1000 && excelFile.size < 1000000
                          ? (excelFile.size / 1000).toFixed(2) + " KB"
                          : (excelFile.size / 1000000).toFixed(2) + " MB"}
                      </span>
                    </div>
                  </span>
                ) : (
                  <span className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                    <span className="font-medium text-gray-600">
                      Drop files to Attach, or{" "}
                      <span className="!text-yellow-500 underline">browse</span>
                    </span>
                  </span>
                )}
              </label>
            </div>
            {errors.file && <div className="text-red-500">{errors.file}</div>}
            {errorMsg && <div className="text-red-500">{errorMsg}</div>}
            {showWarning && (
              <p className="text-red-500 text-center">Please select a file</p>
            )}
          </div>
        </div>

        <div className="flex items-center justify-end mt-4 space-x-2 text-white">
          <>
            <button
              type="submit"
              className="btnSecondary"
              onClick={(e) => {
                e.preventDefault();
                handleSampleExport(false);
              }}
            >
              Download sample
            </button>
          </>

          <button
            type="submit"
            className="btnPrimary bg-green-600"
            onClick={(e) => handleSubmit(e)}
          >
            Import
          </button>
          <button
            type="button"
            className="btnPrimary bg-red-500"
            onClick={handleModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
}

export default ImportUpdateExcelModal;

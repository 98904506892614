import React, { useState, useEffect, useRef } from "react";
const TagsInputAutoComplete = (props) => {
  const {
    tags,
    setTags,
    placeholder,
    className,
    disable,
    readOnly,
    label,
    name,
    isFloat,
    tagBg,
    data,
    idField,
    valueField,
    onQueryChange,
    onSelect,
    setAutoComLoader,
    isAutoComLoader,
    selectedItem,
    extraClass,
    PreLocationType,
    setLocationdata,
    width,
    height,
    /* rowId, */
    optionClass
  } = props;

  const [query, setQuery] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const autocompleteRef = useRef(null);
      

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
        setAutoComLoader(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setAutoComLoader]);

  useEffect(() => {
    if (selectedItem === null || selectedItem === "") {
      setQuery("");
    } else if (selectedItem) {
      setQuery(selectedItem[valueField] || "");
    }
  }, [selectedItem, valueField]);

  const handleChange = (event) => {
    const newQuery = event.target.value;
    console.log(query);
    setQuery(newQuery);
    setLocationdata([]);
    onQueryChange(newQuery, PreLocationType);
    setIsDropdownVisible(true);
    if (newQuery === "") {
      setAutoComLoader(false);
    }
  };

  const handleSelect = (item) => {
    addTags(item);
    setQuery(item[valueField]);
    setIsDropdownVisible(false);
  };

  const removeTags = (selectedTag) => {
    setTags([...tags?.filter((tag) => tag.id !== selectedTag.id)]);
  };
  const addTags = (event) => {
    if (event && event.id && !tags.find((e) => e.id === event.id)) {
      setTags([...tags, event]);
      document.getElementById("custom-tag-input-autocomplete").value = "";
      onSelect(event);
    }
  };
  return (
    <div className="relative w-full" ref={autocompleteRef}>
      <div
        className={`form-control inline-flex flex-wrap !flex items-center relative min-h-[40px] h-auto ${className}`}
      >
        {label && (
          <label
            htmlFor={name}
            className={[
              `small`,
              isFloat
                ? "text-[rgba(0,_0,_0,_0.6)] absolute -left-[5px] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] translate-x- -translate-y-[13px] scale-75 px-1"
                : "",
            ].join(" ")}
            style={{
              background: "linear-gradient(0deg, white 50%, transparent 50%)",
            }}
          >
            {label}
          </label>
        )}
        {tags?.map((tag, index) => (
          <div
            className={`badge me-1 pe-1 badge justify-between items-center !mr-2 my-0.5 h-[26.39px] ml-0 rounded-md ${
              tagBg ? tagBg : "bg-sky-500 text-white "
            }`}
            key={index}
          >
            <span className="m-2 text-sm">{tag[valueField]}</span>
            <button
              data-testid="tag-clean-element"
              aria-label="remove path fragment"
              tabindex="-1"
              className="border-0 bg-transparent ps-auto pe-0 text-sm"
              onClick={() => removeTags(tag)}
              type="button"
            >
              {" "}
              &times;
            </button>
          </div>
        ))}
        {!disable && (
          <div
            className={`flex items-center w-full overflow-hidden ${
              width ?? ""
            } ${height ?? ""} ${extraClass ?? ""} `}
          >
            <input
              type="text"
              id="custom-tag-input-autocomplete"
              placeholder={placeholder ? placeholder : "Add new data"}
              className={`w-full focus:ring-0 outline-none flex-fill input-tags ${
                tags?.length > 0 ? "!border-l border-[#D9D9D9] px-2 h-full" : ""
              } `}
              onChange={handleChange}
              readOnly={readOnly}
              disabled={disable}
            />
            {isAutoComLoader && (
              <div
                className={`absolute z-10  bg-white border-sm border-gray-300 rounded-sm shadow-sm max-h-30 overflow-auto w-full ${
                  optionClass ?? ""
                }`}
              >
                <img src="/img/loading.gif" className="w-7" alt="" />
              </div>
            )}
          </div>
        )}
        {isDropdownVisible && data?.length > 0 && (
          <ul
            className={`fixed z-999999 mt-0 bg-amber-100 border-sm border-gray-300 rounded-sm shadow-sm max-h-60 overflow-auto min-w-[140px] ${
              optionClass ?? ""
            }`}
          >
            {data.map((item) => (
              <li
                key={item[idField]}
                onClick={() => handleSelect(item)}
                className="p-2 hover:bg-gray-200 cursor-pointer border-b border-gray-200 "
              >
                {item[valueField]}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default TagsInputAutoComplete;

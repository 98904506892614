import React, { useState, useCallback } from "react";
import dayjs from "dayjs";
import { InputField } from "../../components";
import api from "../../services/api";
import CustomTreeData from "./CustomTreeData";
import Loading from "../../components/Loader/Loading";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import { Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import debounce from "lodash/debounce";
import AutoComplete from "../../components/common/AutoComplete";
const Mailbox = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const queryParams = new URLSearchParams(location.search);
  const emailId = queryParams.get("emailId");
  const backlinkId = queryParams.get("backlinkId");
  const [isBacklinkDomains, setBacklinksDomains] = useState([]);
  const [isAutoComLoader, setAutoComLoader] = useState(false);
  const [paginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const initalData = {
    subjectText: null,
    fromEmail: emailId || "",
    numberOfMails: null,
    backlink: backlinkId || "",
    emailDate: dayjs(new Date()).format("YYYY-MM-DD"),
    backlink_data: {},
  };
  const [isLoader, setIsLoader] = React.useState(false);
  const [emailList, setEmailList] = React.useState([]);
  const [selectedPayload, setSelectedPayload] = React.useState(initalData);
  const [selectedItem, setSelectedItem] = useState(
    {
      _id: "",
      backlink_id: selectedPayload?.backlink,
    } || ""
  );
  const [expandedRow, setExpandedRow] = useState(null);
  const [additionalData, setAdditionalData] = useState(null);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSelectedPayload({ ...selectedPayload, [name]: value });
  };
  const get_docusign_template_list = async (filter, reset = null) => {
    setExpandedRow(null);
    setAdditionalData([]);
    setIsLoader(true);
    const payload = reset
      ? { date: filter?.emailDate }
      : {
          subjectText: filter?.subjectText,
          fromEmail: filter?.fromEmail,
          date: filter?.emailDate,
          numberOfMails: filter?.numberOfMails,
          ...(filter?.backlink_data?.backlink_id || filter?.backlink
            ? {
                backlink:
                  filter?.backlink_data?.backlink_id || filter?.backlink,
              }
            : {}),
        };

    const queryParams = Object.entries(payload)
      .filter(([key, value]) => value !== null && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    const dynamicUrl = `q=&${queryParams}`;
    try {
      const res = await api.get(`api/linkbuilder_user/get_mails?${dynamicUrl}`);
      if (res.status === 200) {
        setEmailList(res.data);
      }
      setIsLoader(false);
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message || "Please reload the page and try again!"
      );
      setEmailList([]);
      setIsLoader(false);
    }
    setIsLoader(false);
  };
  React.useEffect(() => {
    get_docusign_template_list(selectedPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onReset = () => {
    navigate(location.pathname);
    setSelectedPayload({
      subjectText: null,
      fromEmail: "",
      numberOfMails: null,
      backlink: "",
      emailDate: dayjs(new Date()).format("YYYY-MM-DD"),
      backlink_data: {},
    });
    setSelectedItem("");
    get_docusign_template_list(initalData, "reset");
    setExpandedRow(null);
    setAdditionalData([]);
  };

  const renderContent = (content) => {
    const isHTML = /<\/?[a-z][\s\S]*>/i.test(content);
    if (isHTML) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/html");
      const plainText = doc.body.textContent || "";
      return plainText;
    }

    return content;
  };
  const handleRowClick = async (itemId) => {
    if (expandedRow === itemId?.id) {
      setExpandedRow(null);
      setAdditionalData([]);
    } else {
      if (itemId) {
        setAdditionalData(itemId);
        setExpandedRow(itemId?.id);
      }
    }
  };
  /* const handleSelectedOptionsChange = (event) => {
    setSelectedPayload({
      ...selectedPayload,
      backlink: event.target.value,
    });
  }; */

  const readFrom = (response) => {
    response = response.trim();
    const quotedMatch = response.match(/^"([^"]+)"\s*</);
    if (quotedMatch) {
      return quotedMatch[1];
    }
    const angleBracketMatch = response.match(/^([^<\s]+)(?:\s*<|$)/);
    if (angleBracketMatch) {
      return angleBracketMatch[1].trim();
    }
    return response.split("<")[0].trim();
  };

  const handleSelect = (item) => {
    setSelectedPayload((prevState) => ({
      ...prevState,
      backlink_id: item?.backlink_id,
      backlink_data: {
        backlink_id: item?.backlink_id,
        _id: item?._id,
      },
    }));
    setSelectedItem(item);
  };
  const handleClearSelect = () => {
    setSelectedPayload((prevState) => ({
      ...prevState,
      backlink_id: "",
      backlink: "",
      backlink_data: {},
    }));
    setSelectedItem(null);
  };
  const fetchData = useCallback(async (searchQuery) => {
    if (searchQuery?.length === 0) {
      setBacklinksDomains([]);
      return;
    }
    const payload = {
      user_id: user?.user?.id,
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      is_assigned: true,
      review_task_page: true,
      filter: {
        groupOp: "AND",
        rules: [
          {
            field: "b.backlink_domain",
            op: "cn",
            data: searchQuery,
          },
        ],
      },
    };
    setAutoComLoader(true);
    try {
      const res = await api.post("/api/backlinks/", payload);
      if (res.status === 200 || res.status === 201) {
        setBacklinksDomains(
          res?.data?.backlinksData?.map((item) => ({
            _id: item["b.id"],
            backlink_id: item["b.backlink_domain"],
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
    setAutoComLoader(false);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQueryChange = useCallback(
    debounce((newQuery) => fetchData(newQuery), 500),
    []
  );

  return (
    <div className="px-6 pt-4">
      <ToastContainer />
      <CustomBreadcrumbs
        crumbs={[
          { label: "Task List", link: "/" },
          { label: "Mailbox", link: "" },
        ]}
      />
      <div className="h-full p-4 bg-neutral-1 bg-white border mt-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 items-center">
          <InputField
            placeholder={"Subject Text"}
            onChange={handleChange}
            name="subjectText"
            value={selectedPayload?.subjectText}
            showFilterIcon={true}
          />
          <InputField
            placeholder={"From Email"}
            onChange={handleChange}
            name="fromEmail"
            value={selectedPayload?.fromEmail}
            showFilterIcon={true}
          />
          <InputField
            placeholder={"No. of Mails"}
            onChange={handleChange}
            name="numberOfMails"
            value={selectedPayload?.numberOfMails}
            showFilterIcon={true}
          />

          <AutoComplete
            data={isBacklinkDomains}
            idField="_id"
            valueField="backlink_id"
            onSelect={handleSelect}
            onQueryChange={handleQueryChange}
            placeholder="Search Backlink"
            setAutoComLoader={setAutoComLoader}
            isAutoComLoader={isAutoComLoader}
            selectedItem={selectedItem}
            onClear={handleClearSelect}
            width="w-[236px]"
            height="h-[42px]"
            extraClass="mr-2 mt-1"
          />

          {/* <SelectBox
            placeholder={"Select Backlink"}
            onChange={handleSelectedOptionsChange}
            name="Backlinks"
            valueKey="backlink_domain"
            labelKey="backlink_domain"
            options={backlinks}
            value={selectedPayload?.backlink}
            selectValue="Select Backlink"
            height="h-[42px]"
          /> */}
          <div className="col-span-1 flex flex-col sm:flex-row items-center sm:items-center">
            <InputField
              placeholder={"Email Date"}
              onChange={handleChange}
              name="emailDate"
              value={selectedPayload?.emailDate}
              type={"date"}
              showFilterIcon={true}
            />
            <div className="flex mt-2 sm:mt-0 sm:ml-3">
              <button
                className="py-1.5 px-3 mr-2 bg-secondary text-white btnSecondary"
                onClick={() => get_docusign_template_list(selectedPayload)}
              >
                Search
              </button>
              <button
                className="py-1.5 px-3 bg-[#233b5b] text-white cursor-pointer relative z-10 rounded"
                onClick={onReset}
              >
                Reset
              </button>
            </div>
          </div>
          <div className="col-span-1 flex justify-center sm:justify-end">
            <Tooltip
              title={
                emailList?.length && emailList[0]?.email ? (
                  <Typography color="inherit">
                    <span>{emailList[0]?.email}</span>
                  </Typography>
                ) : (
                  "-"
                )
              }
              arrow
            >
              <img
                src="/img/user.png"
                alt="Avatar"
                className="w-[40px] rounded-full ml-2"
              />
            </Tooltip>
          </div>
        </div>
      </div>
      {isLoader && <Loading />}
      <div className="bg-white my-3 border rounded">
        <CustomTreeData
          searchBox
          totalRecords={emailList?.length}
          tdClass={"!whitespace-normal text-wrap"}
          heads={[" ", "Sr#", "From", "Subject", "Time"]}
          items={emailList?.map((item, index) => ({
            id: item?.id,
            serialNumber: index + 1,
            from: readFrom(item?.from),
            subject: item?.subject,
            mailcontent: item?.htmlContent,
            isRead: item.isRead,
            time: dayjs(item.time).format("ddd, MMM D, YYYY h:mm A"),
            snippet: item?.snippet,
          }))}
          rowClass="flex"
          cellClass="flex-1"
          onRowClick={handleRowClick}
          expandedRow={expandedRow}
          additionalData={additionalData}
          renderContent={renderContent}
        />
      </div>
    </div>
  );
};
export default Mailbox;

import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { HiPlus } from "react-icons/hi";
import Loading from "../../components/Loader/Loading";
import { DataTable } from "../../components";
import api from "../../services/api";

export default function AssignUsersModal({
  roles,
  handleAssign,
  isSubUsers,
  selectedUser,
  fetchUsersListing,
}) {
  const [users, setUsers] = useState([]);
  const [userListFilter, setUserListFilter] = useState("all");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAssigned, setShowAssigned] = useState(false); 

  useEffect(() => {
    fetchUsersData();
    isSubUsers
      ? setSelectedUsers(isSubUsers.split(",").map(Number))
      : setSelectedUsers([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allUserIds = users
        .filter(
          (user) => userListFilter === "all" || userListFilter === user.role
        )
        .map((user) => user.id);
      setSelectedUsers(allUserIds);
    } else {
      setSelectedUsers([]);
    }
  };

  const handleSelectUser = (e, userId) => {
    if (e.target.checked) {
      setSelectedUsers((prevSelected) => [...prevSelected, userId]);
    } else {
      setSelectedUsers((prevSelected) =>
        prevSelected.filter((id) => id !== userId)
      );
    }
  };

  const fetchUsersData = async () => {
    setIsLoading(true);
    try {
      const payload = {
        page: 1,
        perPage: 1000,
      };
      const response = await api.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/users/users_report`,
        payload
      );
      setUsers(
        response?.data?.records?.filter(
          (item) => item.level >= selectedUser?.level
        )
      );
    } catch (error) {
      console.error(`Error fetching users data: ${error}`);
    }
    setIsLoading(false);
  };

  const assignUsers = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      user_id: selectedUser?.id,
      sub_user_ids: selectedUsers.join(","),
    };
    api
      .put(
        `${process.env.REACT_APP_PUBLIC_API}/api/users/assign_sub_users`,
        payload
      )
      .then((res) => {
        setSelectedUsers([]);
        handleAssign(false);
        selectedUsers && selectedUsers.length === 0
          ? toast.success("users removed successfully.")
          : toast.success("users assigned successfully.");
        fetchUsersListing();
      })
      .catch((err) => toast.error(err.response.data.message));
    setIsLoading(false);
  };

  return (
    <div className="p-2 px-2 lg:px-2">
      <ToastContainer />
      {isLoading && <Loading />}
      <div className="flex items-end justify-between mt-2 dark:text-white pr-2">
        <div className="flex flex-row items-center w-full space-x-2">
          <select
            name="User Type"
            className="inputField w-[250px] h-[40px] text-black p-2 dark:bg-transparent dark:border-white/30 dark:text-white cursor-pointer rounded-md"
            value={userListFilter}
            onChange={(e) => setUserListFilter(e.target.value)}
          >
            <option value="all" className="dark:bg-blue">
              All Roles
            </option>
            {roles.map((role, index) => (
              <option className="dark:bg-blue" key={index} value={role.name}>
                {role.name}
              </option>
            ))}
          </select>

          <button
            type="button"
            className={`text-sm hover:bg-blue-700 py-2 px-4 rounded btnTable text-black ${
              showAssigned === false
                ? "border-l-4 border-secondary bg-blue/10"
                : "bg-white"
            }`}
            onClick={() => setShowAssigned(false)}
          >
            All
            <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
              {users.length}
            </span>
          </button>

          <button
            type="button"
            className={`text-sm hover:bg-blue-700 py-2 px-4 rounded btnTable text-black ${
              showAssigned === "assigned"
                ? "border-l-4 border-secondary bg-blue/10"
                : "bg-white"
            }`}
            onClick={() => setShowAssigned("assigned")}
          >
            Assigned
            <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
              {selectedUsers.length}
            </span>
          </button>

          <button
            type="button"
            className={`text-sm hover:bg-blue-700 py-2 px-4 rounded btnTable text-black ${
              showAssigned === "notAssigned"
                ? "border-l-4 border-secondary bg-blue/10"
                : "bg-white"
            }`}
            onClick={() => setShowAssigned("notAssigned")}
          >
            Not Assigned
            <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
              {users.length - selectedUsers.length}
            </span>
          </button>
        </div>
      </div>
      <DataTable
        heads={[
          <input
            type="checkbox"
            onChange={handleSelectAll}
            className="form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2"
            checked={users
              ?.filter(
                (user) =>
                  userListFilter === "all" || userListFilter === user.role
              )
              .every((user) => selectedUsers.includes(user.id))}
          />,
          "Sr#",
          "Name",
          "Username",
          "Email",
          "Role",
          "Merchant Name",
        ]}
        items={users
          ?.filter((user) => {
            const matchesRole =
              userListFilter === "all" || userListFilter === user.role;
            if (showAssigned === "assigned") {
              return matchesRole && selectedUsers.includes(user.id);
            } else if (showAssigned === "notAssigned") {
              return matchesRole && !selectedUsers.includes(user.id);
            }
            return matchesRole;
          })
          .map((user, index) => ({
            checkbox: (
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2"
                onChange={(e) => handleSelectUser(e, user.id)}
                checked={selectedUsers.includes(user.id)}
              />
            ),
            serialnumber: index + 1,
            name: user["name"],
            username: user["username"],
            email: user["email"],
            role: user["role"],
            merchant_name: user["merchant_info"]["company_name"],
          }))}
        tableHeight="max-h-80"
      />
      <div className="flex items-cente justify-end mt-5 space-x-2 text-white">
        <button className="btnPrimary" onClick={(e) => assignUsers(e)}>
          <HiPlus className="w-5 h-5" />
          Assign Users
        </button>
      </div>
    </div>
  );
}

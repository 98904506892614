import axios from "axios";
import React, { useState } from "react";
import { FullContainer } from "../../components";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import { useDispatch } from "react-redux";
import {
  setInstructionModal,
  setIsBrowserActive,
  setIsBrowserDomain,
  setLoggedInUser,
} from "../../store/slices/authSlice";
import dayjs from "dayjs";

const CustomInput = ({ label, error, ...props }) => {
  return (
    <div className="space-y-1">
      <label className="block text-gray-700 font-medium">{label}</label>
      <div className="relative">
        <input
          {...props}
          className="w-full h-12 px-4 rounded-xl border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors outline-none text-gray-800 placeholder:text-gray-400"
        />
        {props.type === "password" && (
          <button
            type="button"
            className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
            onClick={props.onTogglePassword}
          >
            {props.showPassword ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                <path
                  fillRule="evenodd"
                  d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
              </svg>
            )}
          </button>
        )}
      </div>
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  );
};

const SignupForm = ({ onSwitchToLogin }) => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    const newErrors = {};
    if (!formData.username.trim()) newErrors.username = "Username is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Invalid email format";
    if (!formData.password) newErrors.password = "Password is required";
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords don't match";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      setIsLoading(true);
      /*     const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/auth/register`,
        {
          username: formData.username,
          email: formData.email,
          password: formData.password,
        }
      ); */
      toast.success("Registration successful! Please login.");
      onSwitchToLogin();
    } catch (err) {
      toast.error(err?.response?.data?.message || "Registration failed");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-[400px] mx-auto w-full">
      <h1 className="text-3xl font-bold text-gray-800 mb-2">
        Create Account 🚀
      </h1>
      <p className="text-gray-500 mb-7">
        Sign up to get started with RoboAnywhere
      </p>

      <form onSubmit={handleSubmit} className="space-y-5">
        <div className="space-y-5">
          <CustomInput
            label="Username"
            type="text"
            placeholder="Choose a username"
            value={formData.username}
            onChange={(e) =>
              setFormData({ ...formData, username: e.target.value })
            }
            error={errors.username}
          />
          <CustomInput
            label="Email"
            type="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            error={errors.email}
          />
          <CustomInput
            label="Password"
            type={showPassword ? "text" : "password"}
            placeholder="Create a password"
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
            error={errors.password}
            showPassword={showPassword}
            onTogglePassword={() => setShowPassword(!showPassword)}
          />
          <CustomInput
            label="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm your password"
            value={formData.confirmPassword}
            onChange={(e) =>
              setFormData({ ...formData, confirmPassword: e.target.value })
            }
            error={errors.confirmPassword}
            showPassword={showConfirmPassword}
            onTogglePassword={() =>
              setShowConfirmPassword(!showConfirmPassword)
            }
          />
        </div>

        {isLoading ? (
          <div className="w-full bg-primary/80 rounded-xl py-3.5 text-center">
            <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin mx-auto" />
          </div>
        ) : (
          <button
            className="w-full bg-primary hover:bg-primary/90 text-white rounded-xl py-3.5 font-medium transition-colors"
            type="submit"
          >
            Create Account
          </button>
        )}

        <div className="text-center text-gray-600 pt-4">
          Already have an account?{" "}
          <button
            onClick={onSwitchToLogin}
            className="text-secondary hover:text-secondary/80 font-medium transition-colors"
          >
            Sign in
          </button>
        </div>
      </form>
    </div>
  );
};

const ForgotPasswordForm = ({ onSwitchToLogin }) => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email.trim()) {
      setError("Please enter your email address");
      return;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Please enter a valid email address");
      return;
    }

    try {
      setIsLoading(true);
      /* const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/auth/forgot-password`,
        { email }
      ); */
      setIsEmailSent(true);
      toast.success("Password reset instructions have been sent to your email");
    } catch (err) {
      toast.error(err?.response?.data?.message || "Failed to process request");
      setError(err?.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-[400px] mx-auto w-full">
      <h1 className="text-3xl font-bold text-gray-800 mb-2">
        Forgot Password? 🔑
      </h1>
      <p className="text-gray-500 mb-10">
        Enter your email address to reset your password
      </p>

      {isEmailSent ? (
        <div className="text-center space-y-6">
          <div className="bg-green-50 rounded-xl p-6">
            <div className="flex justify-center mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 text-green-500"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
              </svg>
            </div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">
              Check Your Email
            </h3>
            <p className="text-gray-600 mb-6">
              We've sent password reset instructions to your email address.
            </p>
            <button
              onClick={onSwitchToLogin}
              className="text-secondary hover:text-secondary/80 font-medium transition-colors"
            >
              Back to Login
            </button>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-5">
            <CustomInput
              label="Email"
              type="email"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
              error={error}
            />
          </div>

          {isLoading ? (
            <div className="w-full bg-primary/80 rounded-xl py-3.5 text-center">
              <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin mx-auto" />
            </div>
          ) : (
            <button
              className="w-full bg-primary hover:bg-primary/90 text-white rounded-xl py-3.5 font-medium transition-colors"
              type="submit"
            >
              Reset Password
            </button>
          )}

          <div className="text-center text-gray-600 pt-4">
            Remember your password?{" "}
            <button
              onClick={onSwitchToLogin}
              className="text-secondary hover:text-secondary/80 font-medium transition-colors"
            >
              Back to Login
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default function LoginForm() {
  const [currentView, setCurrentView] = useState("login"); // "login" | "signup" | "forgot-password"
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const handleStopBrowserModal = async (event) => {
    try {
      const launch = await api.post("/api/multilogin/stop_browser_static_ip");
      if (launch.status === 200) {
        const urlLaunch = await axios.get(launch.data.stop_url, {
          headers: { Authorization: `Bearer ${launch.data.token}` },
        });
        if (urlLaunch.data?.status?.http_code === 200) {
          const update = await api.post(
            "/api/multilogin/update_browser_status_static_ip",
            {
              is_browser_active: 0,
            }
          );
          if (update.status === 200) {
            dispatch(setIsBrowserActive(null));
            dispatch(setIsBrowserDomain(null));
          }
        }
      }
    } catch (error) {
      if (
        error?.response?.data?.status?.message === "profile already stopped"
      ) {
        dispatch(setIsBrowserActive(null));
        dispatch(setIsBrowserDomain(null));
        return await api.post(
          "/api/multilogin/update_browser_status_static_ip",
          {
            is_browser_active: 0,
          }
        );
      }
      console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
      const update = await api.post(
        "/api/multilogin/update_browser_status_static_ip",
        {
          is_browser_active: 0,
        }
      );
      if (update.status === 200) {
        dispatch(setIsBrowserActive(null));
        dispatch(setIsBrowserDomain(null));
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    if (!username.trim()) {
      setErrors({ username: "Please enter your username." });
      return;
    }

    if (!password.trim()) {
      setErrors({ password: "Please enter your password." });
      return;
    }
    const data = {
      username: username,
      password: password,
    };
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/auth`,
        data
      );
      dispatch(setLoggedInUser(response?.data));
      if (
        response?.data?.user?.show_popup &&
        (response?.data?.user?.role_id === 3 ||
          response?.data?.user?.role_id === 8)
      ) {
        dispatch(setInstructionModal(true));
      }
      const today = dayjs().format("YYYY-MM-DD"); // Get YYYY-MM-DD format
      localStorage.setItem("user", JSON.stringify(response?.data));
      localStorage.setItem("accessToken", response?.data?.accessToken);
      localStorage.setItem("refreshToken", response?.data?.refreshToken);
      localStorage.setItem("lastLoginDate", today);
      dispatch(setIsBrowserActive(null));
      dispatch(setIsBrowserDomain(null));
      if (response?.data?.is_browser_active) {
        handleStopBrowserModal();
      }
      // window.location.reload();
      setUsername("");
      setPassword("");
    } catch (err) {
      toast.error(err?.response?.data?.message || "Unknown Error");
    }
    setIsLoading(false);
  };

  return (
    <div id="LoginForm" className="h-screen overflow-hidden">
      <FullContainer className="min-h-screen p-2 sm:p-6 bg-login">
        <div className="w-full max-w-[1000px] bg-white/90 backdrop-blur-sm rounded-2xl sm:rounded-[32px] overflow-hidden flex flex-col md:flex-row shadow-2xl relative z-10">
          {/* Left Side - Illustration */}
          <div className="w-full md:w-1/2 bg-primary p-4 sm:p-6 lg:p-12 relative overflow-hidden">
            {/* Decorative elements - simplified for mobile */}
            <div className="absolute inset-0 overflow-hidden">
              {/* Simplified background elements for mobile */}
              <div className="absolute -top-[30%] -left-[30%] w-[80%] h-[80%] rounded-full bg-gradient-to-r from-secondary/30 to-transparent blur-3xl" />
              <div className="absolute top-[15%] right-[10%] w-8 h-8 md:w-12 md:h-12 rounded-full bg-white/10" />
              <div className="absolute bottom-[20%] left-[10%] w-12 h-12 md:w-16 md:h-16 rounded-full bg-white/10" />
            </div>

            {/* Content - Simplified for mobile */}
            <div className="relative z-10 h-full flex flex-col">
              {/* Logo */}
              <div className="mb-4 md:mb-12">
                <div className="inline-flex items-center space-x-3 bg-white/10 px-4 py-2 rounded-full backdrop-blur-sm">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-5 h-5 md:w-6 md:h-6 -mb-1 text-white"
                  >
                    <path d="M11.7 2.805a.75.75 0 0 1 .6 0A60.65 60.65 0 0 1 22.83 8.72a.75.75 0 0 1-.231 1.337 49.948 49.948 0 0 0-9.902 3.912l-.003.002c-.114.06-.227.119-.34.18a.75.75 0 0 1-.707 0A50.88 50.88 0 0 0 7.5 12.173v-.224c0-.131.067-.248.172-.311a54.615 54.615 0 0 1 4.653-2.52.75.75 0 0 0-.65-1.352 56.123 56.123 0 0 0-4.78 2.589 1.858 1.858 0 0 0-.859 1.228 49.803 49.803 0 0 0-4.634-1.527.75.75 0 0 1-.231-1.337A60.653 60.653 0 0 1 11.7 2.805Z" />
                  </svg>
                  <span className="text-white font-medium">RoboAnywhere</span>
                </div>
              </div>

              {/* Main Content - Simplified for mobile */}
              <div className="flex-grow">
                <h1 className="text-2xl md:text-4xl font-bold text-white leading-tight">
                  Roboanywhere
                  <br />
                  <span className="text-secondary">
                    Manage backlinks and Link builders
                  </span>
                </h1>

                {/* Stats - Responsive grid */}
                <div className="mt-4 md:mt-6 grid grid-cols-2 gap-3 md:gap-6">
                  <div className="border border-white/10 rounded-xl md:rounded-2xl px-3 py-2 md:px-5 md:py-4">
                    <div className="text-xl md:text-2xl font-bold text-white mb-1 md:mb-2">
                      2M+
                    </div>
                    <div className="text-white/60 text-xs md:text-sm">
                      Active Users
                    </div>
                  </div>
                  <div className="border border-white/10 rounded-xl md:rounded-2xl px-3 py-2 md:px-5 md:py-4">
                    <div className="text-xl md:text-2xl font-bold text-white mb-1 md:mb-2">
                      98%
                    </div>
                    <div className="text-white/60 text-xs md:text-sm">
                      Success Rate
                    </div>
                  </div>
                </div>

                {/* Features List - Hidden on smallest screens, visible on sm and up */}
                <div className="hidden sm:block space-y-2 mt-4 md:mt-5">
                  <div className="flex items-center space-x-3 text-white/80">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-4 h-4 md:w-5 md:h-5 text-secondary"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-sm md:text-base">
                      Automated Workflow
                    </span>
                  </div>
                  <div className="flex items-center space-x-3 text-white/80">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-4 h-4 md:w-5 md:h-5 text-secondary"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-sm md:text-base">
                      Intelligent Platform
                    </span>
                  </div>
                  <div className="flex items-center space-x-3 text-white/80">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-4 h-4 md:w-5 md:h-5 text-secondary"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-sm md:text-base">24/7 Support</span>
                  </div>
                </div>
              </div>

              {/* Footer - Hidden on mobile, visible on md and up */}
              <div className="hidden md:block mt-auto pt-14">
                <div className="flex items-center justify-between text-white/40 text-sm">
                  <span>© 2024 RoboAnywhere</span>
                  <div className="flex items-center space-x-4">
                    <a href="/" className="hover:text-white transition-colors">
                      Terms
                    </a>
                    <a href="/" className="hover:text-white transition-colors">
                      Privacy
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Side - Forms */}
          <div className="w-full md:w-1/2 bg-white p-6 sm:p-12 flex flex-col justify-center">
            {currentView === "login" && (
              <div className="max-w-[400px] mx-auto w-full">
                <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-2">
                  Welcome Back! 👋
                </h1>
                <p className="text-gray-500 mb-6 sm:mb-7">
                  Enter your credentials to access your account
                </p>

                <form onSubmit={handleSubmit} className="space-y-5">
                  <div className="space-y-5">
                    <CustomInput
                      label="Username"
                      type="text"
                      placeholder="Enter your username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      error={errors.username}
                    />
                    <CustomInput
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      error={errors.password}
                      showPassword={showPassword}
                      onTogglePassword={() => setShowPassword(!showPassword)}
                    />
                  </div>

                  <div className="flex items-center justify-between">
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="w-4 h-4 rounded border-gray-300 text-primary focus:ring-primary"
                      />
                      <span className="text-gray-600">Remember me</span>
                    </label>
                    <button
                      type="button"
                      onClick={() => setCurrentView("forgot-password")}
                      className="text-secondary hover:text-secondary/80 font-medium transition-colors"
                    >
                      Forgot Password?
                    </button>
                  </div>

                  {isLoading ? (
                    <div className="w-full bg-primary/80 rounded-xl py-3.5 text-center">
                      <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin mx-auto" />
                    </div>
                  ) : (
                    <button
                      className="w-full bg-primary hover:bg-primary/90 text-white rounded-xl py-3.5 font-medium transition-colors"
                      type="submit"
                    >
                      Sign In
                    </button>
                  )}

                  <div className="relative my-8">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-200"></div>
                    </div>
                    <div className="relative flex justify-center">
                      <span className="bg-white px-4 text-sm text-gray-500">
                        Or continue with
                      </span>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <button
                      type="button"
                      className="flex items-center justify-center gap-2 bg-gray-50 hover:bg-gray-100 text-gray-700 rounded-xl py-3 font-medium transition-colors group"
                    >
                      <svg className="w-5 h-5" viewBox="0 0 24 24">
                        <path
                          d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                          fill="#4285F4"
                        />
                        <path
                          d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                          fill="#34A853"
                        />
                        <path
                          d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                          fill="#FBBC05"
                        />
                        <path
                          d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                          fill="#EA4335"
                        />
                      </svg>
                      Google
                    </button>
                    <button
                      type="button"
                      className="flex items-center justify-center gap-2 bg-gray-50 hover:bg-gray-100 text-gray-700 rounded-xl py-3 font-medium transition-colors group"
                    >
                      <svg
                        className="w-5 h-5"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 2C6.477 2 2 6.477 2 12c0 4.42 2.865 8.164 6.839 9.489.5.092.682-.217.682-.482 0-.237-.008-.866-.013-1.7-2.782.604-3.369-1.341-3.369-1.341-.454-1.155-1.11-1.462-1.11-1.462-.908-.62.069-.608.069-.608 1.003.07 1.531 1.03 1.531 1.03.892 1.529 2.341 1.087 2.91.831.092-.646.35-1.086.636-1.336-2.22-.253-4.555-1.11-4.555-4.943 0-1.091.39-1.984 1.029-2.683-.103-.253-.446-1.27.098-2.647 0 0 .84-.269 2.75 1.025A9.578 9.578 0 0112 6.836c.85.004 1.705.114 2.504.336 1.909-1.294 2.747-1.025 2.747-1.025.546 1.377.203 2.394.1 2.647.64.699 1.028 1.592 1.028 2.683 0 3.842-2.339 4.687-4.566 4.935.359.309.678.919.678 1.852 0 1.336-.012 2.415-.012 2.743 0 .267.18.578.688.48C19.138 20.161 22 16.418 22 12c0-5.523-4.477-10-10-10z"
                        />
                      </svg>
                      Github
                    </button>
                  </div>

                  <div className="text-center text-gray-600 pt-4">
                    Don't have an account?{" "}
                    <button
                      onClick={() => setCurrentView("signup")}
                      className="text-secondary hover:text-secondary/80 font-medium transition-colors"
                    >
                      Sign up
                    </button>
                  </div>
                </form>
              </div>
            )}

            {currentView === "signup" && (
              <SignupForm onSwitchToLogin={() => setCurrentView("login")} />
            )}

            {currentView === "forgot-password" && (
              <ForgotPasswordForm
                onSwitchToLogin={() => setCurrentView("login")}
              />
            )}
          </div>
        </div>
      </FullContainer>
      <ToastContainer />
    </div>
  );
}

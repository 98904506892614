import { QueueListIcon, RectangleGroupIcon } from "@heroicons/react/24/solid";
import {
  HiUserCircle,
  HiUserGroup,
  HiViewGrid,
  HiLink,
  HiGlobe,
  HiCog,
} from "react-icons/hi";
import { osName } from "react-device-detect";
import { MdDownload } from "react-icons/md";
const handleDownload = async () => {
  const response = await fetch("https://api.roboanywhere.com/launcher.yaml");
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "launcher.yaml";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

const handleAgentDownload = () => {
  let downloadLink;

  if (osName === "Windows") {
    // Windows download link
    downloadLink =
      "https://mlxdists.s3.eu-west-3.amazonaws.com/mlx/1.16.0/multiloginx_setup-amd64.exe";
  } else if (osName === "Mac OS") {
    // Additional check for M-series or Intel on macOS\
    const isAppleSilicon = (() => {
      // Try to detect architecture using navigator.userAgentData if available
      if (
        navigator.userAgentData?.platform === "macOS" &&
        navigator.userAgentData?.architecture === "arm"
      ) {
        return true; // Likely Apple Silicon
      }
      // Fallback checks based on platform and userAgent
      if (navigator.platform === "MacIntel") {
        return false; // Intel Mac
      } else if (
        navigator.platform === "MacARM64" ||
        /Apple M/.test(navigator.userAgent)
      ) {
        return true; // Apple Silicon (M-series)
      }

      return false; // Default to Intel if unknown
    })();
    downloadLink = isAppleSilicon
      ? "https://cdn-mlx-prod.multiloginapp.com/mlx/latest/multiloginx-arm64.pkg"
      : "https://cdn-mlx-prod.multiloginapp.com/mlx/latest/multiloginx-amd64.pkg";
  }

  // Create an invisible anchor element and trigger the download
  const link = document.createElement("a");
  link.href = downloadLink;
  link.download = downloadLink.split("/").pop(); // Set the download attribute with filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); // Clean up
};

export const menuItems = {
  dashboard: {
    to: "/dashboard",
    label: "Dashboard",
    Icon: HiViewGrid,
    allowedRoles: [1, 3],
  },
  tasklist: {
    to: "/task-list",
    label: "Task List",
    Icon: QueueListIcon,
    allowedRoles: [3],
  },
  settings: {
    to: "/dashboard/settings",
    label: "Settings",
    Icon: HiCog,
    allowedRoles: [1], // Accessible by role level 1
    children: {
      // linkBuilders: {
      //   to: "/settings/link-builders",
      //   label: "Link Builders",
      //   Icon: HiUserGroup,
      //   allowedRoles: [1],
      // },
      // linkBuildersReport: {
      //   to: "/link-builders/reports",
      //   label: "Link Builders Reports",
      //   Icon: HiUserGroup,
      //   allowedRoles: [1],
      // },
      users: {
        to: "/settings/users",
        label: "Users",
        Icon: HiUserGroup,
        allowedRoles: [1],
      },
      roles: {
        to: "/settings/roles",
        label: "Roles",
        Icon: HiUserCircle,
        allowedRoles: [1],
      },
      permissions: {
        to: "/settings/permissions",
        label: "Permissions",
        Icon: HiUserCircle,
        allowedRoles: [1],
      },
      templates: {
        to: "/settings/templates",
        label: "Templates",
        Icon: HiLink,
        allowedRoles: [1],
      },
      merchants: {
        to: "/settings/merchants",
        label: "Merchants",
        Icon: HiUserGroup,
        allowedRoles: [1],
      },
      copyUrlButton: {
        label: "Renew Mail Token",
        Icon: HiLink,
        allowedRoles: [1],
        action: () => {
          const url =
            "https://api.roboanywhere.com/api/public/request_refresh_token";
          navigator.clipboard
            .writeText(url)
            .then(() => {
              alert(`URL copied: ${url}`);
            })
            .catch((err) => {
              console.error("Failed to copy URL:", err);
            });
        },
      },
      holdReason: {
        to: "/settings/hold-reason",
        label: "Hold Reason",
        Icon: HiLink,
        allowedRoles: [1],
      },
      nicheProduct: {
        to: "/settings/niche-category",
        label: "Niche Category",
        Icon: HiLink,
        allowedRoles: [1],
      },
      nicheSubCategory: {
        to: "/settings/niche-sub-category",
        label: "Niche Sub Category",
        Icon: HiLink,
        allowedRoles: [1],
      },
    },
  },

  backlinks: {
    to: "/backlinks",
    label: "Backlinks",
    Icon: HiLink,
    allowedRoles: [1],
    children: {
      list: {
        to: "/backlinks/backlinks-list",
        label: "Backlinks",
        Icon: HiLink,
        allowedRoles: [1],
      },
      backlinksSeo: {
        to: "/backlinks/seo-backlinks-list",
        label: "SEO Backlinks",
        Icon: HiLink,
        allowedRoles: [1],
      },
      backlinksType: {
        to: "/backlinks/backlinks-type",
        label: "Backlink Type",
        Icon: HiLink,
        allowedRoles: [1],
      },
      backlinksContent: {
        to: "/backlinks/content",
        label: "Backlink Content",
        Icon: HiLink,
        allowedRoles: [1],
      },
      backlinkReadyStatus: {
        to: "/backlinks/backlink-ready-status",
        label: "Backlink Ready Status",
        Icon: HiLink,
        allowedRoles: [1],
      },
    },
  },

  batches: {
    to: "/batches",
    label: "Batches",
    Icon: RectangleGroupIcon,
    allowedRoles: [1],
  },

  domains: {
    to: "/domains",
    label: "Domains",
    Icon: HiGlobe,
    allowedRoles: [1],
  },
  reports: {
    to: "/reports",
    label: "Reports",
    Icon: HiLink,
    allowedRoles: [1],
    children: {
      linkbuilderTaskReport: {
        to: "/reports/link-builders",
        label: "Linkbuilders Tasks Report",
        Icon: HiLink,
        allowedRoles: [1],
      },
      performanceReport: {
        to: "/reports/performance-report",
        label: "Linkbuilders Performance Report",
        Icon: HiLink,
        allowedRoles: [1],
      },
      leadPerformanceReport: {
        to: "/reports/leads-performance-report",
        label: "Leader Performance Report",
        Icon: HiLink,
        allowedRoles: [1],
      },
      domainReport: {
        to: "/reports/money-domain-report",
        label: "Money Domain Report",
        Icon: HiLink,
        allowedRoles: [1],
      },
      reviewTaskReport: {
        to: "/reports/review-task",
        label: "Review Tasks",
        Icon: HiLink,
        allowedRoles: [1],
      },
      taskReport: {
        to: "/reports/task-report",
        label: "Task Report",
        Icon: HiLink,
        allowedRoles: [1],
      },
      usersActivityReport: {
        to: "/reports/users-activity-report",
        label: "Users Activity Report",
        Icon: HiLink,
        allowedRoles: [1],
      },
    },
  },
  downloads: {
    to: "/downloads",
    label: "Downloads",
    Icon: HiLink,
    allowedRoles: [1],
    children: {
      downloadAgent: {
        label: "Download Agent",
        Icon: MdDownload,
        allowedRoles: [1],
        action: () => handleAgentDownload(),
      },
      downloadKey: {
        label: "Download Key",
        Icon: MdDownload,
        allowedRoles: [1],
        action: () => handleDownload(),
      },
    },
  },
};

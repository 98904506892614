import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import Loading from "../../components/Loader/Loading";
import MUIDataTable from "../../components/MUIGrid";
import { FaSearch } from "react-icons/fa";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton, TablePagination } from "@mui/material";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import dayjs from "dayjs";
import { columns } from "./columns";
import ImportSeoModal from "./ImportSeoModal";
export default function DailyTasks() {
  const [isRecords, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [toggleStates] = useState({});
  const [showCategoryTooltip, setCategoryShowTooltip] = useState(null);
  const [showLocationTooltip, setLocationShowTooltip] = useState(null);
  const [isImport, setIsImport] = useState(true);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });

  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });

  const [backlinksTypeList, setBacklinksTypeList] = useState([]);
  const [backlinksStatusList, setBacklinksStatusList] = useState([]);
  const [nicheProductList, setNicheProductList] = useState([]);
  const followStatus = [
    { value: "Nofollow", label: "Nofollow" },
    { value: "Dofollow", label: "Dofollow" },
    { value: "Pending", label: "Pending" },
  ];
  const locationTypes = [
    { value: "local", label: "Local" },
    { value: "national", label: "National" },
  ];

  const fetchListing = async (filter) => {
    if (!filter.loaderTerminated) {
      setIsLoading(true);
    }
    let payload = { ...filter };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const response = await api.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/list_seo_backlinks`,
        payload
      );
      setRecords(response.data);
    } catch (error) {
      console.error(`Error fetching users data: ${error}`);
    }
    if (!filter.loaderTerminated) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchListing({
      filters: queryOptions,
      page: 1,
      perPage: paginationModel.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      fetchListing({
        filters: queryOptions,
        page: 1,
        perPage: paginationModel.pageSize,
        loaderTerminated: true,
      }),
      getBacklinkTypeList,
      getBacklinkStatusList,
      getNicheProductList,
    ])
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="mb-3 mt-3 flex items-center justify-between">
        <div className="flex">
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            ref={setFilterButtonEl}
          />

          <MUIButton
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            Apply filter
          </MUIButton>
        </div>
        <div className="flex items-center">
          <TablePagination
            color="primary"
            shape="rounded"
            size="medium"
            showFirstButton
            showLastButton
            count={
              isRecords?.totalRecords
                ? isRecords?.totalRecords
                : isRecords?.dataRows?.length || 0
            }
            page={paginationModel.page - 1 || 0}
            onPageChange={(e, page) => handleTopPageChange(page)}
            rowsPerPage={paginationModel.pageSize || 100}
            onRowsPerPageChange={(e) => handleTopPageSizeChange(e.target.value)}
            component="div"
            rowsPerPageOptions={[
              25,
              50,
              75,
              100,
              250,
              500,
              1000,
              1500,
              2000,
              {
                label: "All",
                value: isRecords?.totalRecords
                  ? isRecords?.totalRecords
                  : isRecords?.data?.length || 0,
              },
            ]}
          />
        </div>
      </GridToolbarContainer>
    );
  }

  const getBacklinkStatusList = () => {
    api
      .get(`/api/backlinks/backlink_status_list`)
      .then((res) => setBacklinksStatusList(res.data))
      .catch((err) => console.log(err));
  };
  //   Get Type List
  const getBacklinkTypeList = () => {
    api
      .get(`/api/backlinks/backlink_type_list`)
      .then((res) => setBacklinksTypeList(res.data))
      .catch((err) => console.log(err));
  };

  const getNicheProductList = () => {
    api
      .get(`/api/backlinks/niche_products`)
      .then((res) => setNicheProductList(res.data))
      .catch((err) => console.log(err));
  };

  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = isRecords?.data?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (data) => {
    return selectedData.some((item) => item["b.id"] === data["b.id"]);
  };
  const onSingleSelect = ({ checked, data }) => {
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (item) => item["b.id"] !== data["b.id"]
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columnDefs = columns({
    backlinksTypeList: backlinksTypeList,
    backlinksStatusList: backlinksStatusList,
    followStatus: followStatus,
    toggleStates: toggleStates,
    backlinksData: isRecords?.data,
    selectedData: selectedData,
    onSelectAll: onSelectAll,
    isSelected: isSelected,
    onSingleSelect: onSingleSelect,
    isLoading: isLoading,
    nicheProductList: nicheProductList,
    locationTypes,
    showCategoryTooltip,
    setCategoryShowTooltip,
    showLocationTooltip,
    setLocationShowTooltip,
  });

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    fetchListing({
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };

  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    fetchListing({
      filters: queryOptions,
      page: +page + 1,
      perPage: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    fetchListing({
      filters: queryOptions,
      page: 1,
      perPage: newPageSize,
    });
  };

  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    fetchListing({
      filters: queryOptions,
      page: 1,
      perPage: paginationModel.pageSize,
    });
  };

  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      fetchListing({
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataRows = (isRecords?.data || [])?.map((record, index) => {
    const counter = index + 1;
    const records = record;
    const {
      "b.id": id,
      "b.backlink_domain": backlink_domain,
      "b.money_domain": money_domain,
      "b.type": type,
      "b.domain_rating": domain_rating,
      "b.ready_status": ready_status,
      "b.follow_status": follow_status,
      "b.traffic": traffic,
      "b.backlink_type_id": backlink_type_id,
      "b.ready_status_id": ready_status_id,
      "b.good_to_assign": good_to_assign,
      "b.niche_categories": niche_categories,
      "b.niche_product_ids": niche_product_ids,
      "b.location_type": location_type,
      "b.location_names": location_names,
      "b.location_ids": location_ids,
      "b.intersect_count": intersect_count,
      "b.backlink_paid_status": backlink_paid_status,
      "b.is_verified": is_verified,
      "b.spam_score": spam_score,
    } = record;
    const created_at = dayjs(record["b.created_at"]).format(
      "ddd, MMM D, YYYY h:mm A"
    );
    const updated_at = dayjs(record["b.updated_at"]).format(
      "ddd, MMM D, YYYY h:mm A"
    );

    return {
      counter,
      records,
      "b.id": id,
      "b.backlink_domain": backlink_domain,
      "b.money_domain": money_domain,
      "b.type": type || "",
      "b.domain_rating": domain_rating,
      "b.ready_status": ready_status || "",
      "b.follow_status": follow_status || "",
      "b.traffic": traffic,
      "b.backlink_type_id": backlink_type_id,
      "b.ready_status_id": ready_status_id,
      "b.good_to_assign": good_to_assign,
      "b.niche_categories": niche_categories,
      "b.niche_product_ids": niche_product_ids,
      "b.location_type": location_type,
      "b.location_names": location_names,
      "b.location_ids": location_ids,
      "b.intersect_count": intersect_count,
      "b.backlink_paid_status": backlink_paid_status,
      "b.created_at": created_at,
      "b.updated_at": updated_at,
      "b.is_verified": is_verified,
      "b.spam_score": spam_score,
    };
  });

  return (
    <div className="px-6 pt-4">
      <div className="flex items-center justify-between">
        <CustomBreadcrumbs
          crumbs={[
            { label: "Home", link: "/" },
            { label: "SEO Backlinks", link: "" },
          ]}
        />
        <div className="flex flex-row">
          <button
            className="flex items-center gap-2 btnSecondary mr-1"
            onClick={() => setIsImport(!isImport)}
          >
            Import SEO Backlinks
          </button>
        </div>
      </div>
      {!isImport && (
        <ImportSeoModal
          isImport={isImport}
          setOpen={setIsImport}
          fetchBacklinksData={fetchListing}
        />
      )}
      <ToastContainer />
      {isLoading && <Loading />}

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={dataRows}
          CustomToolbar={CustomToolbar}
          height="80vh"
          searchable="No"
          showCount="No"
          totalItems={isRecords?.totalRecords}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
          onFilterModelChange={onFilterChange}
        />
      </div>
    </div>
  );
}

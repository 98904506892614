import React, { useEffect, useRef, useState } from "react";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { Modal } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import { MdDelete } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";
import userPermission from "../../utils/userPermission";
import Loading from "../../components/Loader/Loading";
import MUIDataTable from "../../components/MUIGrid";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import AddUpdateForm from "./AddUpdateForm";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import HoldBacklinksModal from "./HoldBacklinksModal";
export default function HoldReasons() {
  const cancelButtonRef1 = useRef(false);
  const [isHoldReasons, setHoldReasons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedReason, setSelectedReason] = useState({});
  const [holdTaks, setHoldTasks] = useState([]);
  const [isHoldTaskModal, setHoldTaskModal] = useState(false);
  const handleHoldTaskModal = () => {
    setHoldTaskModal(!isHoldTaskModal);
  };
  const fetchHoldReasonData = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/hold_reasons`
      );
      setHoldReasons(response.data);
    } catch (error) {
      console.error(`Error fetching users data: ${error}`);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchHoldReasonData();
  }, []);

  // Modal
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const handleNewReason = () => {
    handleModal(true);
    setModalType("new");
  };

  const handleEditReason = (data) => {
    setSelectedReason(data);
    setModalType("edit");
    handleModal();
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="mb-3 mt-3 flex items-center justify-between">
        <div className="flex">
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            ref={setFilterButtonEl}
          />
        </div>
        <div className="flex">
          <button className="btnPrimary mr-2" onClick={handleNewReason}>
            Create Reason
          </button>
        </div>
      </GridToolbarContainer>
    );
  }
  const columnDefs = [
    { headerName: "Sr#", field: "counter", width: 200, filterable: false },
    {
      headerName: "Hold Reason",
      field: "hold_reason",
      flex: 1,
      minWidth: 300,
    },
    {
      headerName: "Actions",
      field: "",
      minWidth: 200,
      filterable: false,
      renderCell: (params) => (
        <div className="relative">
          <div className="flex items-center justify-center">
            <FaInfoCircle
              key={`${params?.row.id}-action`}
              className="w-4 h-4 cursor-pointer text-black-400 mr-1"
              onClick={() => openDataModal(params.row.records)}
              title="Hold Backlinks"
            />
            {userPermission("Edit Reason") ? (
              <PencilSquareIcon
                key={`${params?.row.id}-action`}
                className="h-5 cursor-pointer text-black-400"
                onClick={() => handleEditReason(params.row.records)}
                title="Edit Reason"
              />
            ) : null}
            {userPermission("Delete Reason") ? (
              <MdDelete
                className="h-5 cursor-pointer text-black-400 ml-1"
                title="Delete Reason"
                onClick={(e) => deleteReason(params.row.records.id)}
                size={20}
              />
            ) : null}
          </div>
        </div>
      ),
    },
  ];

  const deleteReason = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (!isConfirmed) return;
    api
      .delete(`/api/backlinks/hold_reasons/${id}`)
      .then((res) => {
        toast.success("Reason Removed Successfully.");
        fetchHoldReasonData();
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const openDataModal = async (records) => {
    setHoldTasks([]);
    setIsLoading(true);
    try {
      const response = await api.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/linkbuilder_user/hold_tasks`,
        {
          hold_reason_id: records?.id,
        }
      );
      setHoldTasks(response?.data);
    } catch (error) {
      console.error(`Error fetching data: ${error}`);
      toast.error(
        error?.response?.data?.message ||
          "An error occurred while fetching data."
      );
    }
    setHoldTaskModal(true);
    setIsLoading(false);
  };

  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Hold Reason", link: "" },
        ]}
      />
      <ToastContainer />
      {isLoading && <Loading />}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={isHoldReasons?.map((data, index) => {
            const { hold_reason } = data;
            return {
              records: data,
              counter: index + 1,
              hold_reason,
            };
          })}
          CustomToolbar={CustomToolbar}
          height="88vh"
          searchable="No"
          showCount="No"
          pagination="No"
          paginationMode="client"
        />
      </div>
      <Modal
        open={open}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-xl"
        title={
          (modalType === "new" && "Create Reason") ||
          (modalType === "edit" && "Edit Reason")
        }
      >
        <AddUpdateForm
          handleModal={handleModal}
          fetchData={fetchHoldReasonData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          modalType={modalType}
          reasons={selectedReason}
        />
      </Modal>
      {holdTaks?.backlinks?.length ? (
        <Modal
          open={!isHoldTaskModal}
          handleModal={handleHoldTaskModal}
          cancelButtonRef={cancelButtonRef1}
          className="max-w-4xl "
          title={"Hold Backlinks"}
        >
          <HoldBacklinksModal logsData={holdTaks} />
        </Modal>
      ) : null}
    </div>
  );
}

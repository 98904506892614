import React, { useState, useEffect, useRef } from "react";
import { BiLogOut } from "react-icons/bi";
import userPermission from "../../utils/userPermission";
import jwtDecode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import {
  setInstructionModal,
  setLoggedInUser,
  setIsBrowserActive,
  setIsBrowserDomain,
} from "../../store/slices/authSlice";
import { MdInfoOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "axios";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loader/Loading";
import { FaRegClock } from "react-icons/fa";
import { Modal } from "../../components";
import PriorHoursModal from "./PriorHoursModal";
export default function EclipseDropdown() {
  const { user } = useSelector((state) => state.auth);
  const realtimeMails = useSelector(
    (state) => state?.realtimeMails?.task_email
  );
  const cancelPriorRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isChildUserList, setChildUserList] = useState([]);
  const [isUserForSwitch, setUserForSwitch] = useState("");
  const [isPriorHours, setPriorHours] = useState(false);
  const jwtkeys = jwtDecode(user?.accessToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await api.get(`${process.env.REACT_APP_PUBLIC_API}/api/logout`);
      localStorage.clear();
      dispatch(setLoggedInUser(null));
    } catch (err) {
      console.log("👊 ~ handleLogout ~ err:", err);
    }
    setIsLoading(false);
  };
  const get_user = async () => {
    const filters = {
      groupOp: "AND",
      rules: [{ field: "is_active", op: "eq", data: 1 }],
    };
    try {
      const res = await api.post("/api/users/users_report", {
        filters,
        page: 1,
        perPage: 1000,
      });
      if (res.status === 200) {
        const userId = user?.user?.id;
        if (userId) {
          const filteredRecords = res.data?.records
            .filter((user) => user.id !== userId)
            .sort((a, b) => a.name.localeCompare(b.name));
          setChildUserList(filteredRecords);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  // const getChildUsers = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await api.get(
  //       `${process.env.REACT_APP_PUBLIC_API}/api/users/list_sub_users?user_id=${jwtkeys?.parent_id}`
  //     );
  //     setChildUserList(response?.data);
  //   } catch (error) {
  //     console.error(`Error fetching users data: ${error}`);
  //   }
  //   setIsLoading(false);
  // };
  useEffect(() => {
    get_user();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSwitchUser = async (e, user) => {
    setIsLoading(true);
    const switchUser = async (url, data = {}) => {
      setUserForSwitch(data?.sub_user_id);
      try {
        const response = await api.post(url, data);
        if (response?.status === 200) {
          localStorage.removeItem("merchant");
          const { data } = response;
          dispatch(setLoggedInUser(data));
          if (
            data?.user?.show_popup &&
            (data?.user?.role_id === 3 || data?.user?.role_id === 8)
          ) {
            dispatch(setInstructionModal(false));
          }
          localStorage.setItem("user", JSON.stringify(data));
          localStorage.setItem("accessToken", data?.accessToken);
          localStorage.setItem("refreshToken", data?.refreshToken);
          if (data?.is_browser_active) {
            handleStopBrowserModal();
          }
          toast.success("Switch user successfully");
          data?.user?.role_id && data?.user?.role_id === 8
            ? navigate("/task-list")
            : navigate("/dashboard");
          window.location.reload();
        }
      } catch (error) {
        setUserForSwitch(user?.user?.id);
        console.error(`Error fetching users data: ${error}`);
        toast.error(error?.response?.data?.message);
      }
    };

    if (e.target.value && e.target.value !== "parent") {
      await switchUser(`/api/users/switch_user`, {
        sub_user_id: e.target.value,
      });
    } else {
      if (jwtkeys?.id === jwtkeys?.parent_id) {
        toast.error("You can't switch to parent!");
        setIsLoading(false);
        return;
      }
      await switchUser(`/api/users/switch_to_parent`);
    }
    setIsLoading(false);
  };
  const handleStopBrowserModal = async (event) => {
    setIsLoading(true);
    try {
      const launch = await api.post("/api/multilogin/stop_browser_static_ip");
      if (launch.status === 200) {
        const urlLaunch = await axios.get(launch.data.stop_url, {
          headers: { Authorization: `Bearer ${launch.data.token}` },
        });
        if (urlLaunch.data?.status?.http_code === 200) {
          const update = await api.post(
            "/api/multilogin/update_browser_status_static_ip",
            {
              is_browser_active: 0,
            }
          );
          if (update.status === 200) {
            dispatch(setIsBrowserActive(null));
            dispatch(setIsBrowserDomain(null));
          }
        }
      }
    } catch (error) {
      if (
        error?.response?.data?.status?.message === "profile already stopped"
      ) {
        dispatch(setIsBrowserActive(null));
        dispatch(setIsBrowserDomain(null));
        return await api.post(
          "/api/multilogin/update_browser_status_static_ip",
          {
            is_browser_active: 0,
          }
        );
      }
      const update = await api.post(
        "/api/multilogin/update_browser_status_static_ip",
        {
          is_browser_active: 0,
        }
      );
      if (update.status === 200) {
        dispatch(setIsBrowserActive(null));
        dispatch(setIsBrowserDomain(null));
      }
      console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
    }
    setIsLoading(false);
  };
  const params = new URLSearchParams();
  if (realtimeMails?.email) {
    params.append("emailId", realtimeMails.email);
  }
  if (realtimeMails?.backlink) {
    params.append("backlinkId", realtimeMails.backlink);
  }
  return (
    <div className="navDropdownButton relative">
      {isLoading && <Loading />}
      <div className="flex items-center justify-end mr-0 text-right cursor-pointer w-fit">
        {/* {userPermission("Download Ke") ? (
          <div className="ml-2 hidden capitalize md:block text-md text-gray-600 font-bold">
            {user && user?.user?.merchant_id
              ? user?.user?.merchant_id?.company_name
              : ""}
          </div>
        ) : null} */}
        <select
          name="Users"
          className="text-black p-2 dark:bg-transparent dark:border-white/30 dark:text-white cursor-pointer rounded-md mr-2 border hover:text-black w-[200px]"
          onChange={(e) => handleSwitchUser(e, user)}
          value={isUserForSwitch}
        >
          <option value="all" className="dark:bg-blue">
            {user && user?.user?.name
              ? user?.user?.name?.replace(/\b\w/g, (char) => char.toUpperCase())
              : "Switch User"}
          </option>
          {jwtkeys && jwtkeys?.id !== jwtkeys.parent_id && (
            <option value="parent" className="dark:text-white">
              Back to Parent
            </option>
          )}
          {isChildUserList.length > 0 && (
            <>
              {isChildUserList.map((user, index) => (
                <option
                  className="dark:text-white"
                  key={index}
                  value={user?.id}
                >
                  {user?.name?.replace(/\b\w/g, (char) => char.toUpperCase())} (
                  {user?.role ? user?.role : ""})
                </option>
              ))}
            </>
          )}
        </select>
        {userPermission("Last Working Hours") ? (
          <button
            onClick={() => setPriorHours(true)}
            title="Last Working Hours"
            className="pageLink hover:bg-blue/10 dark:hover:bg-white/10 capitalize mr-2 w-fit text-secondary bg-white  border hover:bg-secondary hover:text-white px-2"
          >
            <FaRegClock size={18} className="" />
          </button>
        ) : null}
        <button
          onClick={() => dispatch(setInstructionModal(true))}
          title="Download Instructions"
          className="pageLink hover:bg-blue/10 dark:hover:bg-white/10 capitalize mr-2 w-fit text-secondary bg-white  border hover:bg-secondary hover:text-white px-2"
        >
          <MdInfoOutline size={20} className="" />
        </button>
        {userPermission("Mailbox") ? (
          <Badge
            badgeContent={realtimeMails?.totalRecord}
            color="error"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Link
              to={`/mailbox?${params.toString()}`}
              class="mr-2 relative pageLink hover:bg-blue/10 dark:hover:bg-white/10 capitalize bg-secondary text-white hover:bg-secondary px-4 w-50 h-10"
              title="Mailbox"
              target="_blank"
            >
              <span class="ml-1">Mailbox</span>
            </Link>
          </Badge>
        ) : null}
        {/* <span>
          <p className="font-semibold text-md whitespace-nowrap capitalize">
            {user?.user?.name}
          </p>
        </span> 
        <span className="p-2">|</span>*/}
        <button
          onClick={handleLogout}
          className="hover:rounded hover:bg-gray-100 dark:hover:bg-white/10 w-full transition-all flex items-center text-red-500"
        >
          Logout
          <BiLogOut className="ml-1" />
        </button>
      </div>
      {isPriorHours && (
        <Modal
          open={!isPriorHours}
          handleModal={() => setPriorHours(false)}
          cancelButtonRef={cancelPriorRef}
          className="max-w-3xl"
          title={`Prior Hours`}
        >
          <PriorHoursModal userId={user?.user?.id} />
        </Modal>
      )}
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../services/api";
import Loading from "../../../components/Loader/Loading";
import MUIDataTable from "../../../components/MUIGrid";
import { FaSearch } from "react-icons/fa";
import { Modal, Badge } from "../../../components";
import { FaBook } from "react-icons/fa";
import DateRangeComp from "../../../components/DateRangePicker";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton, TablePagination } from "@mui/material";
import { IoEyeSharp } from "react-icons/io5";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
import LogsModal from "./LogsModal";
import { Pie } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import dayjs from "dayjs";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

export default function DailyTasks() {
  const [isRecords, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [isDataModal, setDataModal] = useState(false);
  const [logsData, setLogsData] = useState([]);
  const cancelButtonRef = useRef(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });

  const [queryOptions, setQueryOptions] = useState({
    groupOp: "",
    rules: [],
  });

  const handleDataModal = () => {
    setDataModal(!isDataModal);
  };
  const taskStatusOptions = [
    { value: "pending", label: "Pending" },
    { value: "inProcess", label: "In Process" },
    { value: "inReview", label: "In Review" },
    { value: "hold", label: "Hold" },
    { value: "cancelled", label: "Cancelled" },
    { value: "completed", label: "Completed" },
    { value: "reOpen", label: "Reopen" },
  ];

  const customTaskStatusOpt = [
    { value: "pending", label: "Assigned" },
    { value: "inProcess", label: "Marked As: In Process" },
    { value: "inReview", label: "Marked As: In Review" },
    { value: "hold", label: "Marked As: On Hold" },
    { value: "cancelled", label: "Marked As: Cancelled" },
    { value: "completed", label: "Marked As: Completed" },
    { value: "reOpen", label: "Marked As: Reopen" },
  ];

  const initialFilters = {
    start_date: dayjs().subtract(7, "day").startOf("day").toDate(),
    end_date: new Date().toISOString().slice(0, 23) + "Z",
    report_task_status: "pending",
    completed_in_more_then_days: "",
  };

  const [customFilters, setCustomFilters] = useState(initialFilters);

  const fetchListing = async (filter) => {
    if (!filter.loaderTerminated) {
      setIsLoading(true);
    }
    let payload = {
      ...filter,
      ...(filter?.resetFilter
        ? {
            start_date: dayjs().subtract(7, "day").startOf("day").toDate(),
            end_date: new Date().toISOString().slice(0, 23) + "Z",
            report_task_status: "pending",
            completed_in_more_then_days: "",
          }
        : {
            ...customFilters,
          }),
    };

    if (!filter?.filters?.rules?.length) {
      delete payload.filters;
    }
    if (filter?.resetFilter) {
      delete payload.resetFilter;
    }
    try {
      const response = await api.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/report/task_report`,
        payload
      );
      setRecords(response.data);
    } catch (error) {
      console.error(`Error fetching  data: ${error}`);
    }
    if (!filter.loaderTerminated) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      fetchListing({
        filters: queryOptions,
        page: 1,
        perPage: paginationModel.pageSize,
        loaderTerminated: true,
      }),
      get_user(),
    ])
      .catch((error) => {
        console.error("Error in API calls:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSearch = () => {
    fetchListing({
      filters: [],
      page: 1,
      perPage: paginationModel.pageSize,
    });
    setFilterModel({
      ...filterModel,
      items: [],
    });
  };

  const handleUpdateFilters = (status) => {
    const newFilter = {
      field: "bdb.task_status",
      op: "eq",
      data: status,
    };

    let updatedRules = queryOptions.rules.map((rule) =>
      rule.field === newFilter.field ? { ...rule, data: newFilter.data } : rule
    );

    if (!updatedRules.some((rule) => rule.field === newFilter.field)) {
      updatedRules = [...updatedRules, newFilter];
    }

    let newFilters = {
      groupOp: "AND",
      rules: updatedRules,
    };

    fetchListing({
      filters: newFilters,
      page: 1,
      perPage: paginationModel.pageSize,
    });

    setFilterModel({
      ...filterModel,
      items: newFilters.rules?.map(({ field, op, data }) => ({
        field,
        operator: operator({ operator: op, field }),
        value: data,
      })),
    });

    setQueryOptions(newFilters);
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="mb-3 mt-3 flex items-center justify-between">
        <div className="flex">
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            ref={setFilterButtonEl}
          />

          <MUIButton
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            Apply filter
          </MUIButton>
        </div>
      </GridToolbarContainer>
    );
  }

  function addSpaceBeforeUpperCase(s) {
    return s && s?.replace(/([A-Z])/g, " $1")?.trim();
  }
  const columnDefs = [
    { headerName: "Sr#", field: "counter", width: 20, filterable: false },
    {
      headerName: "Task Status",
      field: "bdb.task_status",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <>
            <span className="capitalize">
              {addSpaceBeforeUpperCase(params?.row?.["bdb.task_status"])}
            </span>
          </>
        );
      },
      type: "singleSelect",
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        status: value?.value,
      }),
      valueOptions: taskStatusOptions,
    },
    {
      field: "u.id",
      headerName: "User",
      minWidth: 120,
      type: "singleSelect",
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.name,
      valueOptions: users,
      valueGetter: (params) => params.row?.["u.id"],
      valueFormatter: (params) => {
        const user = users.find((user) => user.id === params.value);
        return user ? user.name : "";
      },
      renderCell: (params) => {
        const user = users.find((user) => user.id === params.row?.["u.id"]);
        return <>{user ? user.name : "User Not Found"}</>;
      },
    },
    {
      headerName: "Completed Days",
      field: "completed_in_more_then_days",
      width: 100,
      flex: 1,
      filterable: false,
      renderCell: (params) =>
        params?.row.completed_in_more_then_days > 0 ? (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row.completed_in_more_then_days
              ? params?.row.completed_in_more_then_days
              : "-"}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      headerName: "Show Logs",
      field: "",
      filterable: false,
      flex: 1,
      renderCell: (params) => (
        <div class="flex justify-center items-center h-screen">
          <button
            onClick={(e) => openDataModal(params?.row)}
            className="w-full inline-flex items-center justify-center space-x-2"
          >
            <IoEyeSharp className="w-5 h-5" />
          </button>
        </div>
      ),
    },
  ];

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    fetchListing({
      filters: queryOptions,
      page: +params.page + 1,
      perPage: params.pageSize,
    });
  };

  const get_user = async () => {
    const filters = {
      groupOp: "AND",
      rules: [{ field: "is_active", op: "eq", data: 1 }],
    };
    try {
      const res = await api.post("/api/users/users_report", {
        filters,
        page: 1,
        perPage: 1000,
      });
      if (res.status === 200) {
        setUsers(res.data?.records);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    fetchListing({
      filters: queryOptions,
      page: 1,
      perPage: paginationModel.pageSize,
    });
  };

  const operator = ({ operator, field }) => {
    const isSelect =
      columnDefs?.find((item) => item.field === field)?.type === "singleSelect";
    const isNumber =
      columnDefs?.find((item) => item.field === field)?.type === "number";
    const isDate =
      columnDefs?.find((item) => item.field === field)?.type === "date";
    return operator === "cn"
      ? "contains"
      : operator === "eq" && !isSelect && !isNumber
      ? "equals"
      : operator === "eq" && isSelect && !isNumber
      ? "is"
      : operator === "eq" && !isSelect && isNumber
      ? "="
      : operator === "not" && !isSelect && isNumber
      ? "!="
      : operator === "gt" && isDate
      ? "after"
      : operator === "gte" && isDate
      ? "onOrAfter"
      : operator === "lt" && isDate
      ? "before"
      : operator === "lte" && isDate
      ? "onOrBefore"
      : operator === "gt"
      ? ">"
      : operator === "gte"
      ? ">="
      : operator === "lt"
      ? "<"
      : operator === "lte"
      ? "<="
      : operator;
  };

  const [filterModel, setFilterModel] = React.useState({
    items: queryOptions.rules?.map(({ field, op, data }) => ({
      field,
      operator: operator({ operator: op, field }),
      value: data,
    })),
    logicOperator: "and",
    quickFilterValues: [],
    quickFilterLogicOperator: "and",
  });

  const processFilters = (filters) => {
    return (filters || [])?.map((filter) => {
      if (
        filter.operator === "isAnyOf" &&
        filter.value?.length &&
        filter.value[0]?.includes(",")
      ) {
        return {
          ...filter,
          value: filter.value[0].split(",").map((item) => item.trim()),
        };
      }
      return filter;
    });
  };

  const onFilterChange = React.useCallback(
    (filterModel) => {
      const item = processFilters(filterModel?.items || []);
      setFilterModel({
        ...filterModel,
        items: item,
      });
      let ruless = [];
      if (filterModel?.items?.length === 0) {
        fetchListing({
          filters: queryOptions,
          page: 1,
          size: paginationModel.pageSize,
        });
      }
      // eslint-disable-next-line
      processFilters(filterModel?.items)?.map((rule) => {
        ruless = [
          ...ruless,
          {
            field: `${rule.field}`,
            op:
              rule.operator === "contains"
                ? "cn"
                : rule.operator === "equals"
                ? "eq"
                : rule.operator === "is"
                ? "eq"
                : rule.operator === "="
                ? "eq"
                : rule.operator === "!="
                ? "not"
                : rule.operator === ">"
                ? "gt"
                : rule.operator === ">="
                ? "gte"
                : rule.operator === "<"
                ? "lt"
                : rule.operator === "<="
                ? "lte"
                : rule.operator === "onOrBefore"
                ? "lte"
                : rule.operator === "before"
                ? "lt"
                : rule.operator === "onOrAfter"
                ? "gte"
                : rule.operator === "after"
                ? "gt"
                : rule.operator,
            data: rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : rule.value === false
              ? rule.value
              : null,
          },
        ];
      });
      setQueryOptions({
        groupOp: filterModel?.logicOperator?.toUpperCase() || "AND",
        rules: ruless,
      });
      // eslint-disable-next-line
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customFilters]
  );

  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  const dataRows = (isRecords?.records || []).map((record, index) => {
    const counter = offset + index + 1;
    const {
      summary,
      task_id: taskid,
      task_status,
      "u.id": uid,
      "bdb.task_status": bdb_task,
      completed_in_more_then_days,
    } = record;

    return {
      counter,
      taskid,
      summary,
      task_status,
      "u.id": uid,
      "bdb.task_status": bdb_task,
      completed_in_more_then_days,
    };
  });

  const openDataModal = async (record, slug) => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `${process.env.REACT_APP_PUBLIC_API}/api/report/task_log/${record?.taskid}`
      );
      setLogsData(response?.data);
    } catch (error) {
      console.error(`Error fetching data: ${error}`);
    }
    setDataModal(true);
    setIsLoading(false);
  };

  const taskStatus = queryOptions?.rules.find(
    (rule) => rule.field === "bdb.task_status"
  );
  const statusValue = taskStatus?.data || taskStatus?.value;

  const options = {
    responsive: true, // Allow chart to be responsive
    maintainAspectRatio: false, // Prevent chart from maintaining aspect ratio
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          usePointStyle: true,
        },
      },
      datalabels: {
        display: true, // Show datalabels
        color: "black",
        formatter: (value, context) => {
          return (
            (
              (value * 100) /
              context.dataset.data.reduce((a, b) => a + b, 0)
            ).toFixed(2) + "%"
          );
        },
        font: {
          weight: "bold",
        },
      },
    },
  };

  const statusOrder = [
    "pending",
    "inProcess",
    "inReview",
    "hold",
    "cancelled",
    "completed",
    "reOpen",
  ];
  const filteredData = isRecords?.summary?.filter(
    (item) => item["bdb.task_status"] !== null && item["bdb.task_status"] !== ""
  );
  const sortedData = filteredData?.sort((a, b) => {
    const statusA = a["bdb.task_status"];
    const statusB = b["bdb.task_status"];
    return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
  });

  const chartData = {
    labels: sortedData?.map((item) =>
      item["bdb.task_status"]
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase())
    ),
    datasets: [
      {
        data: sortedData?.map((item) => item.totals),
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    fetchListing({
      filters: queryOptions,
      page: +page + 1,
      perPage: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    fetchListing({
      filters: queryOptions,
      page: 1,
      perPage: newPageSize,
    });
  };

  const getColors = (index) => {
    const dataset = chartData?.datasets[0];
    return {
      backgroundColor: dataset.backgroundColor[index],
      borderColor: dataset.borderColor[index],
      dataValue: dataset.data[index],
    };
  };

  const handleReset = () => {
    setFilterModel({
      ...filterModel,
      items: [],
    });
    setCustomFilters((prevFilters) => ({
      ...prevFilters,
      start_date: dayjs().subtract(7, "day").startOf("day").toDate(),
      end_date: new Date().toISOString().slice(0, 23) + "Z",
      report_task_status: "pending",
      completed_in_more_then_days: "",
    }));
    fetchListing({
      resetFilter: true,
      page: 1,
      perPage: paginationModel.pageSize,
    });
  };

  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Task Report", link: "" },
        ]}
      />
      <ToastContainer />
      {isLoading && <Loading />}
      <div className="bg-white my-2 border rounded">
        <div className="flex items-center py-2 justify-between flex-wrap">
          <div className="flex items-center justify-start space-x-2 flex-wrap w-full md:w-auto">
            <select
              disabled={isLoading}
              className="inputField disabled:cursor-not-allowed w-full sm:w-auto py-2 ml-2 mb-2 sm:mb-0"
              value={customFilters.report_task_status}
              onChange={(e) => {
                setCustomFilters((prevFilters) => ({
                  ...prevFilters,
                  report_task_status: e.target.value,
                }));
              }}
            >
              <option value="">Select Report Status</option>
              {customTaskStatusOpt.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <input
              className="inputField disabled:cursor-not-allowed w-full sm:w-[250px] py-2 ml-2 h-[38px] mb-2 sm:mb-0"
              placeholder="Search Completed in days"
              onChange={(e) => {
                setCustomFilters((prevFilters) => ({
                  ...prevFilters,
                  completed_in_more_then_days: e.target.value,
                }));
              }}
              value={customFilters?.completed_in_more_then_days}
            />

            <div className="flex items-center mb-2 sm:mb-0">
              <DateRangeComp
                inputClassName="!font-medium w-full sm:w-[220px] text-center bg-[#e9ecef] text-sm rounded-l !py-1 !px-2 border border-[#ced4da] !mb-0 rounded-0 h-[38px]"
                pickerClassName="!left-1/2 !top-8 -translate-x-[75%]"
                dateRange
                onChange={(e) => {
                  const endDate = new Date(e.selection.endDate);
                  endDate.setHours(23, 59, 59, 59);
                  const formattedEndDate =
                    endDate.toISOString().slice(0, 23) + "Z";

                  const startDate = new Date(e.selection.startDate);
                  startDate.setHours(0, 0, 0, 0);
                  const formattedStartDate =
                    startDate.toISOString().slice(0, 23) + "Z";
                  setCustomFilters({
                    ...customFilters,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                  });
                }}
                startDate={customFilters.start_date}
                endDate={customFilters.end_date}
              />
              <button
                variant="btn_cancel"
                className="h-[38px] !bg-[#042A42] rounded-r px-3 py-1.5 text-white "
                onClick={() => handleSearch()}
              >
                <FaSearch size={14} />
              </button>
            </div>

            <button
              className="btnPrimary ml-1 mb-2 sm:mb-0 h-[38px]"
              onClick={() => handleSearch()}
            >
              Search
            </button>
            <button
              className="btnPrimary  mb-2 sm:mb-0 h-[38px] ml-auto bg-gray-600"
              onClick={() => {
                setQueryOptions({
                  groupOp: "",
                  rules: [],
                });
                handleReset();
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap space-x-1  justify-between items-center">
        <div className="flex items-center justify-start flex-wrap flex-grow md:w-auto">
          {sortedData?.map((statusItem) => (
            <button
              key={statusItem["bdb.task_status"]}
              onClick={() => handleUpdateFilters(statusItem["bdb.task_status"])}
              className={`btnTable ${
                statusValue === statusItem["bdb.task_status"]
                  ? "border-l-4 border-secondary bg-blue/10"
                  : ""
              } px-4 py-2 rounded-md text-sm mb-2 md:mb-0 mr-2`}
            >
              {addSpaceBeforeUpperCase(
                statusItem["bdb.task_status"].charAt(0).toUpperCase() +
                  statusItem["bdb.task_status"].slice(1)
              )}
              <Badge value={statusItem.totals || 0} />
            </button>
          ))}
        </div>

        <div className="flex items-center ml-auto justify-end">
          <TablePagination
            color="primary"
            shape="rounded"
            size="medium"
            showFirstButton
            showLastButton
            count={
              isRecords?.totalRecords
                ? isRecords?.totalRecords
                : isRecords?.records?.length || 0
            }
            page={paginationModel.page - 1 || 0}
            onPageChange={(e, page) => handleTopPageChange(page)}
            rowsPerPage={paginationModel.pageSize || 100}
            onRowsPerPageChange={(e) => handleTopPageSizeChange(e.target.value)}
            component="div"
            rowsPerPageOptions={[
              25,
              50,
              75,
              100,
              250,
              500,
              1000,
              1500,
              2000,
              {
                label: "All",
                value: isRecords?.totalRecords
                  ? isRecords?.totalRecords
                  : isRecords?.records?.length || 0,
              },
            ]}
          />
        </div>
      </div>

      <div className="flex bg-white border rounded  px-4 py-4">
        <div
          className={`w-1/2 border rounded bg-white ${
            !sortedData || sortedData.length === 0 ? "w-full" : ""
          }`}
        >
          <MUIDataTable
            columnDefs={columnDefs}
            items={dataRows}
            CustomToolbar={CustomToolbar}
            height="75vh"
            searchable="No"
            showCount="No"
            totalItems={isRecords?.totalRecords}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            paginationModel={paginationModel}
            onFilterModelChange={onFilterChange}
            filterModel={filterModel}
            isLoading={isLoader}
          />
        </div>

        {sortedData && sortedData?.length > 0 && (
          <div className="w-1/2 p-0 px-4 py-4">
            <div className="w-full" style={{ height: "300px" }}>
              <Pie data={chartData} options={options} />
            </div>
            <div className="flex flex-wrap gap-2 mt-5 px-4 py-4">
              {chartData?.labels?.map((label, index) => {
                const { backgroundColor, borderColor, dataValue } =
                  getColors(index);

                return (
                  <div
                    className="flex items-center p-1 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6"
                    key={index}
                  >
                    <div
                      className="rounded-full border flex justify-center items-center h-[30px] w-[30px]"
                      style={{ backgroundColor, borderColor }}
                    >
                      <FaBook className="text-gray-600" size={14} />
                    </div>
                    <div className="flex flex-col ml-2">
                      <small className="font-pop text-sm font-bold">
                        {dataValue}
                      </small>
                      <small className="font-pop text-xs">{label}</small>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <Modal
        open={!isDataModal}
        handleModal={handleDataModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-4xl"
        title="Task Logs"
      >
        <LogsModal
          logsData={logsData}
          taskStatusOptions={taskStatusOptions}
          users={users}
        />
      </Modal>
    </div>
  );
}

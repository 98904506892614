import React, { useEffect, useRef, useState } from "react";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { Modal } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import { MdDelete } from "react-icons/md";
import userPermission from "../../utils/userPermission";
import Loading from "../../components/Loader/Loading";
import MUIDataTable from "../../components/MUIGrid";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import AddUpdateForm from "./AddUpdateForm";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get_niche_category } from "../../store/slices/nicheCategorySlice";
export default function NicheProducts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoader, setIsLoading] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedProduct, setSelectedProduct] = useState({});
  const { isLoading, record } = useSelector((state) => state.nicheCategory);
  const fetchProductsData = () => {
    dispatch(get_niche_category());
  };
  useEffect(() => {
    if(!record.length){
      fetchProductsData();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Modal
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const handleSubCategory = (id) => {
    localStorage.setItem("niche_category", id);
    navigate(`/settings/niche-sub-category`);
  };

  const handleNewProduct = () => {
    handleModal(true);
    setModalType("new");
  };

  const handleEditProduct = (data) => {
    setSelectedProduct(data);
    setModalType("edit");
    handleModal();
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="mb-3 mt-3 flex items-center justify-between">
        <div className="flex">
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            ref={setFilterButtonEl}
          />
        </div>
        <div className="flex">
          <button className="btnPrimary mr-2" onClick={handleNewProduct}>
            Create Category
          </button>
        </div>
      </GridToolbarContainer>
    );
  }
  const columnDefs = [
    { headerName: "Sr#", field: "counter", width: 200, filterable: false },
    {
      headerName: "Category Name",
      field: "niche_category",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => (
        <div
          className="cursor-pointer"
          onClick={() => handleSubCategory(params.row.id)}
        >
          {params.row.niche_category}
        </div>
      ),
    },
    {
      headerName: "Actions",
      field: "",
      minWidth: 200,
      filterable: false,
      renderCell: (params) => (
        <div className="relative">
          <div className="flex items-center justify-center">
            {userPermission("Edit Niche") ? (
              <PencilSquareIcon
                key={`${params?.row.id}-action`}
                className="h-5 cursor-pointer text-black-400"
                onClick={() => handleEditProduct(params.row.records)}
                title="Edit Niche"
              />
            ) : null}
            {userPermission("Delete Niche") ? (
              <MdDelete
                className="h-5 cursor-pointer text-black-400 ml-1"
                title="Delete Niche"
                onClick={(e) => deleteProduct(params.row.records.id)}
                size={20}
              />
            ) : null}
          </div>
        </div>
      ),
    },
  ];

  const deleteProduct = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (!isConfirmed) return;
    api
      .delete(`/api/backlinks/niche_products/${id}`)
      .then((res) => {
        toast.success("Category Removed Successfully.");
        fetchProductsData();
      })
      .catch((err) => toast.error(err.response.data.message));
  };
  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Niche Category", link: "" },
        ]}
      />
      <ToastContainer />
      {isLoader || ((((isLoading && <Loading />))))}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={record?.map((data, index) => {
            const { niche_category, id } = data;
            return {
              id: id,
              records: data,
              counter: index + 1,
              niche_category,
            };
          })}
          CustomToolbar={CustomToolbar}
          height="88vh"
          searchable="No"
          showCount="No"
          pagination="No"
          paginationMode="client"
        />
      </div>
      <Modal
        open={open}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-xl"
        title={
          (modalType === "new" && "Create Category") ||
          (modalType === "edit" && "Edit Category")
        }
      >
        <AddUpdateForm
          handleModal={handleModal}
          fetchData={fetchProductsData}
          isLoading={isLoader}
          setIsLoading={setIsLoading}
          modalType={modalType}
          products={selectedProduct}
        />
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import Loading from "../../components/Loader/Loading";
import MUIDataTable from "../../components/MUIGrid";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
export default function PriorHoursModal({ userId }) {
  const [isRecords, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  dayjs.extend(duration);

  const [sortingModel, setSortingModel] = React.useState({
    sortField: null,
    sortOrder: null,
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });

  const [queryOptions] = React.useState({
    groupOp: "",
    rules: [
      {
        field: "u.id",
        op: "eq",
        data: userId,
      },
    ],
  });

  const currentDate = new Date();

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const initialFilters = {
    start_date: formatDate(
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      )
    ),
    end_date: formatDate(new Date()),
  };

  const [filters] = React.useState(initialFilters);

  const handleSortModelChange = (params) => {
    setSortingModel({
      sortField: params[0]?.field,
      sortOrder: params[0]?.sort ? params[0]?.sort.toUpperCase() : "default",
    });

    fetchListing({
      filters: queryOptions,
      page: 1,
      perPage: paginationModel.pageSize,
      sortField: params[0]?.field,
      sortOrder: params[0]?.sort ? params[0]?.sort.toUpperCase() : "default",
    });
  };

  const fetchListing = async (filter) => {
    setIsLoading(true);
    let startDate = new Date(filters?.start_date);
    let endDate = new Date(filters?.end_date);
    startDate.setDate(startDate.getDate() - 2);
    endDate.setDate(endDate.getDate() - 1);
    startDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${startDate
      .getDate()
      .toString()
      .padStart(2, "0")} 18:30:00`;

    endDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${endDate
      .getDate()
      .toString()
      .padStart(2, "0")} 18:29:59`;

    let payload = {
      ...filter,
      start_date: startDate,
      end_date: endDate,
    };

    console.log(payload);

    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const response = await api.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/report/user_session_log`,
        payload
      );
      if (response.status === 200 || response.status === 201) {
        setRecords(response.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.error(`Error fetching users data: ${error}`);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchListing({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      perPage: paginationModel.pageSize,
    });
    // eslint-disable-next-line
  }, [userId]);

  const columnDefs = [
    {
      headerName: "Name",
      field: "u.name",
      minWidth: 110,
      sortable: false,
    },
    {
      headerName: "Status",
      field: "u.is_active",
      maxWidth: 110,
      filterable: true,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: [
        { value: 1, label: "Active" },
        { value: 0, label: "In Active" },
      ],
      renderCell: (params) => params.value?.["u.is_active"],
      valueGetter: (params) => params.row?.["u.is_active"],
      valueFormatter: (params) => params.value,
      sortable: false,
    },
    {
      headerName: "Role",
      field: "r.id",
      minWidth: 150,
      type: "singleSelect",
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.name,
      valueOptions: roles,
      renderCell: (params) => params.row["r.name"],
      sortable: false,
    },
    {
      headerName: "Session Duration Date",
      field: "session_duration_date",
      minWidth: 130,
      filterable: false,
      renderCell: (params) =>
        params?.row.total_session_duration ? (
          <span className="text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-purple-100 text-purple-800">
            {getFormattedDateWithOffset(filters.start_date, 1)}
          </span>
        ) : null,
    },
    {
      headerName: "Total Session Duration",
      field: "total_session_duration",
      minWidth: 200,
      filterable: false,
      renderCell: (params) =>
        params?.row.total_session_duration ? (
          <span className=" text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-indigo-100 text-indigo-800">
            {formatTimeToHMS(params?.row.total_session_duration)}
          </span>
        ) : null,
    },
  ];

  const dataRows = (isRecords?.records || [])?.map((record, index) => {
    const counter = index + 1;
    const {
      "u.id": userId,
      "u.name": userName,
      "u.is_active": userIsActive,
      "r.id": roleId,
      "r.name": roleName,
      total_logins,
      avg_session_duration,
      total_session_duration,
      user_sessions,
    } = record;

    const sessionsSummary = user_sessions?.map((session) => ({
      login_time: session.login_time,
      logout_time: session.logout_time,
      session_duration: session.session_duration,
      session_id: session.session_id,
    }));

    return {
      counter,
      "u.id": userId,
      "u.name": userName,
      "u.is_active": userIsActive === 1 ? "Active" : "In Active",
      "r.id": roleId,
      "r.name": roleName,
      total_logins,
      avg_session_duration,
      total_session_duration,
      userSessions: sessionsSummary,
    };
  });

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    fetchListing({
      ...sortingModel,
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/roles`)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatTimeToHMS(timeStr) {
    if (timeStr === "00:00:00" || timeStr === "00:00:00.0000") {
      return "N/A";
    }

    const hasMilliseconds = timeStr.includes(".");

    let parsedTime;

    if (hasMilliseconds) {
      const regex = /^(\d{2}):(\d{2}):(\d{2})\.(\d{4})$/;
      const match = timeStr.match(regex);

      if (match) {
        // Extract hours, minutes, seconds, and milliseconds
        const hours = parseInt(match[1], 10);
        const minutes = parseInt(match[2], 10);
        const seconds = parseInt(match[3], 10);
        const milliseconds = parseInt(match[4], 10);
        parsedTime = dayjs.duration({
          hours,
          minutes,
          seconds,
          milliseconds,
        });
      }
    } else {
      const [hours, minutes, seconds] = timeStr.split(":").map(Number);
      parsedTime = dayjs.duration({
        hours,
        minutes,
        seconds,
      });
    }

    // Build the formatted string, omitting zero values
    const parts = [];

    // Include hours if greater than 0
    if (parsedTime.hours() > 0) {
      parts.push(`${parsedTime.hours()} hours`);
    }

    // Include minutes if greater than 0
    if (parsedTime.minutes() > 0) {
      parts.push(`${parsedTime.minutes()} minutes`);
    }

    // Include seconds if greater than 0
    if (parsedTime.seconds() > 0) {
      parts.push(`${parsedTime.seconds()} seconds`);
    }
    if (parts.length === 0) {
      return "N/A";
    }

    return parts.join(", ");
  }
  function getFormattedDateWithOffset(inputDate, daysOffset) {
    let date = new Date(inputDate);
    date.setDate(date.getDate() - daysOffset);
    let options = { year: "numeric", month: "short", day: "2-digit" };
    let formattedDate = date.toLocaleDateString("en-GB", options);

    return formattedDate;
  }

  return (
    <>
      <ToastContainer />
      {isLoading && <Loading />}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={dataRows}
          height="20vh"
          searchable="No"
          showCount="No"
          totalItems={isRecords?.totalRecords}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
          sortingMode="client"
          toolbar="No"
          onSortModelChange={handleSortModelChange}
          hideFooter="true"
          sx={{
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader--sorted": {
              border: "none",
            },
          }}
        />
      </div>
    </>
  );
}

import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate, Link } from "react-router-dom";
const CustomBreadcrumbs = ({ crumbs, filter }) => {
  const navigate = useNavigate();
  const handleClick = (url) => {
    if (
      filter?.selectedPayload &&
      filter?.selectedPayload?.domain_data?.domain &&
      !filter?.selectedPayload?.user_id
    ) {
      navigate(`${url}`, {
        state: {
          task_status: filter?.selectedPayload.task_status || "",
          backlink: filter?.selectedPayload.backlink || "",
          domain_data: {
            domain: filter?.selectedPayload.domain_data.domain || "",
            domainId: filter?.selectedPayload.domain_data.domainId || "",
          },
        },
      });
    } else if (
      filter?.selectedPayload &&
      (filter?.selectedPayload?.user_id ||
        filter?.selectedPayload.task_status ||
        filter?.selectedPayload.prevPageId || filter?.selectedPayload.backlink_data)
    ) {
      navigate(`${url}`, {
        state: {
          task_status: filter?.selectedPayload.task_status || "",
          start_date: filter?.selectedPayload.start_date || "",
          end_date: filter?.selectedPayload.end_date || "",
          user_id: filter?.selectedPayload.user_id || "",
          prevPageId: filter?.selectedPayload.prevPageId || "",
          backlink_data:filter?.selectedPayload.backlink_data || {},
          page:filter?.selectedPayload.page || "",
          pageSize:filter?.selectedPayload.pageSize || "",
        },
      });
    } else {
      navigate(`${url}`, {
        state: {
          filter: filter?.filter,
          selectedDate: filter?.taskDate,
        },
      });
    }
  };
  return (
    <nav className="text-sm font-medium ml-1" aria-label="Breadcrumb">
      <ol className="flex">
        {crumbs.map((crumb, index) => (
          <li key={index} className="flex items-center">
            {crumb.link ? (
              filter ? (
                <button
                  className="text-blue-500 hover:underline text-lg"
                  onClick={(e) => handleClick(crumb.link)}
                >
                  {crumb.label}
                </button>
              ) : (
                <Link
                  to={crumb.link}
                  className="text-blue-500 hover:underline text-lg"
                >
                  {crumb.label}
                </Link>
              )
            ) : (
              <span className="text-gray-500 text-md">{crumb.label}</span>
            )}
            {index < crumbs.length - 1 && <IoIosArrowForward />}
          </li>
        ))}
      </ol>
    </nav>
  );
};
export default CustomBreadcrumbs;

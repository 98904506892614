import React, { useState, useEffect } from "react";
import CustomPagination from "../../../components/common/CustomPagination";
import { toast } from "react-toastify";
import api from "../../../services/api";

const CustomTopPagination = ({
  data,
  setIsLoading,
  parentFilters,
  prevPageBacklinkId,
  setTopPaginationTasks,
  resetPagination,
}) => {
  const totalPages = data?.length;
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentBlId, setCurrentBlId] = useState("");
  const [debouncedPageIndex, setDebouncedPageIndex] =
    useState(currentPageIndex);

  useEffect(() => {
    if (data[currentPageIndex] && data[currentPageIndex] !== currentBlId) {
      setCurrentBlId(data[currentPageIndex]);
    }
  }, [currentPageIndex, data, currentBlId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedPageIndex(currentPageIndex);
    }, 300);
    return () => clearTimeout(timeout);
  }, [currentPageIndex]);

  useEffect(() => {
    if (currentBlId !== "" && debouncedPageIndex !== currentPageIndex) {
      fetchChildData(currentBlId, debouncedPageIndex, parentFilters);
    }
    // eslint-disable-next-line
  }, [currentBlId, debouncedPageIndex, parentFilters]);

  useEffect(() => {
    setCurrentPageIndex(data?.indexOf(prevPageBacklinkId));
    // eslint-disable-next-line
  }, [data?.length]);

  const handlePageChange = async (newPageIndex) => {
    resetPagination();
    if (newPageIndex < 1 || newPageIndex > totalPages) {
      toast.error(`Please enter a valid page number (1-${totalPages})`);
      return;
    }
    setCurrentPageIndex(newPageIndex - 1);
  };

  const fetchChildData = async (
    currentBlId,
    currentPageIndex,
    parentFilters
  ) => {
    setIsLoading(true);
    const page = 1;
    const pageSize = 100;
    const filters = {
      groupOp: "AND",
      rules: [],
    };

    if (parentFilters?.task_status || parentFilters?.user_id) {
      if (parentFilters?.task_status) {
        filters.rules.push({
          field: "bdb.task_status",
          op: "eq",
          data: parentFilters.task_status,
        });
      }
    } else {
      filters.rules.push({
        field: "bdb.task_status",
        op: "isAnyOf",
        data: ["inReview", "hold"],
      });
    }

    if (parentFilters?.start_date && parentFilters?.end_date) {
      filters.rules.push(
        {
          field: "bdb.updated_at",
          op: "gte",
          data: `'${parentFilters?.start_date}'`,
        },
        {
          field: "bdb.updated_at",
          op: "lte",
          data: `'${parentFilters?.end_date}'`,
        }
      );
    }

    try {
      setIsLoading(true); // Ensure that loading state is set before making the request

      const response = await api.post(
        `/api/report/task_info/${currentBlId}?page=${page}&limit=${pageSize}`,
        {
          filters,
        }
      );

      if (response.status === 200 || response.status === 201) {
        if (response.data?.tasks) {
          const TaskArr = response.data.tasks.map((item) => item.id);
          setTopPaginationTasks(TaskArr);
        } else {
          toast.error("No tasks found.");
        }
      } else {
        toast.error("Failed to fetch data. Please try again later.");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Error fetching data.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="ml-auto mt-2 ">
        <CustomPagination
          currentPage={currentPageIndex + 1}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default CustomTopPagination;

import axios from "axios";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import store from "../store";
import { setLoggedInUser } from "../store/slices/authSlice";
const api = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API,
  headers: {
    "Content-Type": "application/json",
  },
});

let refreshTokenPromise;
const MAX_RETRIES = 5;
api.interceptors.request.use(
  async (config) => {
    const today = dayjs().format("YYYY-MM-DD");
    const { accessToken, refreshToken, lastLoginDate } = localStorage;
    if (accessToken) {
      const jwt = jwtDecode(accessToken);
      if (lastLoginDate && lastLoginDate !== today) {
        // window.alert(
        //   "User logged out due to date change. Please log in again to continue."
        // );
        logout(refreshToken);
        return Promise.reject({
          config: { ...config, _retryCount: 5 },
          message: "Network Error",
          response: {
            status: 401,
            data: { message: "User logged out due to date change." },
          },
        });
      }
      const isExpired = dayjs.unix(jwt.exp).diff(dayjs()) < 3;
      // const lastLoginDate = localStorage.getItem("lastLoginDate");
      if (!isExpired) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      } else {
        if (!refreshTokenPromise) {
          refreshTokenPromise = axios
            .get("/api/refresh", {
              baseURL: process.env.REACT_APP_PUBLIC_API,
              headers: { refreshToken },
            })
            .then((res) => {
              localStorage.setItem("accessToken", res.data.accessToken);
              return res.data.accessToken;
            })
            .catch((err) => {
              console.log("🚀  file: api.js:57  err:", err);
              if (err.response.status === 403 || err.response.status === 401) {
                logout(refreshToken);
              }
              refreshTokenPromise = null; // reset the promise so it can be used again
              throw err; // propagate the error to the original request
            });
        }

        // wait for the refresh token promise to be resolved with the new access token
        config.headers.Authorization = `Bearer ${await refreshTokenPromise}`;
      }
    }

    return config;
  },
  (error) => {
    console.error("Error to call API", error);
  }
);

let isRefreshing = false;
let refreshTokens = [];
const unauthorizedCode = [401, 403];
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log("👊 ~ error:", error);
    const originalRequest = error.config;
    const { refreshToken } = localStorage;

    if (
      (error.response.status === 401 || error.response.status === 403) &&
      error.response?.data?.message ===
        "Session expired due to inactivity. Please log in again to continue." &&
      refreshToken
    ) {
      window.alert(
        "Session expired due to inactivity. Please log in again to continue."
      );
      logout(refreshToken);
      return null;
    }
    if (
      (error.response?.status === 504 ||
        error.message.includes("Network Error")) &&
      !originalRequest._retryCount
    ) {
      originalRequest._retryCount = 0; // Initialize retry count
    }
    if (
      (error.response?.status === 504 ||
        error.message.includes("Network Error")) &&
      originalRequest._retryCount < MAX_RETRIES
    ) {
      originalRequest._retryCount = originalRequest._retryCount || 0; // Initialize if undefined
      originalRequest._retryCount += 1;

      console.warn(
        `Retrying request... (${originalRequest._retryCount}/${MAX_RETRIES})`
      );

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          api(originalRequest)
            .then(resolve) // Resolve the promise on success
            .catch(reject); // Reject the promise on failure
        }, 500); // Retry after 500ms
      });
    }
    // If retries are exhausted, log the error and return to the user
    if (originalRequest._retryCount >= MAX_RETRIES) {
      console.error("Max retries reached. Returning error to user.");
      // Sentry.captureException(error);
      return Promise.reject(error);
    }
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry &&
      refreshToken
    ) {
      originalRequest._retry = true;
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const res = await axios.get("/api/refresh", {
            baseURL: process.env.REACT_APP_PUBLIC_API,
            headers: { refreshToken },
          });
          if (res?.status === 200) {
            localStorage.setItem("accessToken", res.data.accessToken);
            api.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${res.data.accessToken}`;

            // resolve all the requests in the refresh token queue
            refreshTokens.forEach((cb) => cb(res.data.accessToken));
            refreshTokens.length = 0;

            // update the original request with the new access token and send it again
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${res.data.accessToken}`;
            return api(originalRequest);
          } else {
            logout(refreshToken);
            return null;
          }
        } catch (e) {
          if (e && unauthorizedCode.includes(e.response.status)) {
            logout(refreshToken);
            // store.dispatch(onLogout())
          }
          console.error(e);
          return e;
        } finally {
          isRefreshing = false;
        }
      } else {
        // if the token is being refreshed, add the request to the queue
        return new Promise((resolve) => {
          refreshTokens.push((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            resolve(api(originalRequest));
          });
        });
      }
    }

    return Promise.reject(error);
  }
);

function logout(refreshToken) {
  axios.get("/api/logout", {
    baseURL: process.env.REACT_APP_PUBLIC_API,
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });

  localStorage.clear();
  store.dispatch(setLoggedInUser(null));
}

export default api;

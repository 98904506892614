import React, { useState } from "react";
import { DataGridPro /* , GridActionsCellItem */ } from "@mui/x-data-grid-pro";
/* import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save"; */
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../../services/api";
export default function DataGrid({
  columns,
  rows,
  pageSize,
  filterMode,
  onFilterModelChange,
  onEditRowModelChange,
  onCellEditCommit,
  getRowId,
  onOrderChange,
  loading,
  checkboxSelection,
  disableMultipleRowSelection,
  onRowSelectionModelChange,
  apiRef,
  onCellEditStart,
  onPaginationModelChange,
  rowCount,
  rowsPerPageOptions,
  onPageSizeChange,
  components,
  componentsProps,
  rowModesModel,
  editable,
  setRowModesModel,
  processRowUpdate,
  setBacklinksData,
  pagination,
  paginationModel,
  fetchBacklinksData,
  height,
  columnVisibilityModel,
}) {
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [rowIdToDelete /* , setRowIdToDelete */] = useState(0);
  /* const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: "edit" } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: "view" } });
  }; */
  /* const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: "view", ignoreModifications: true },
    });
    const editedRow = rows.find((row) => row["b.id"] === id);
    if (editedRow?.isNew || editedRow?.records?.isNew) {
      setBacklinksData({
        backlinksData: rows.filter((row) => row["b.id"] !== id),
        totalCount: rowCount,
      });
    }
  };
  const handleDeleteClick = (id) => {
    setRowIdToDelete(id);
    setShowDeletePrompt(true);
  }; */
  const handleRowEditStart = (_params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (_params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  /*  const actionColDef = {
    field: "action",
    headerName: "Action",
    type: "actions",
    cellClassName: "actions",
    disableColumnMenu: false,
    width: editable ? "120" : "60",
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === "edit";

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      const actionItems = [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteClick(id)}
          color="inherit"
        />,
      ];

      if (editable) {
        actionItems.push(
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
        );
      }

      return actionItems.reverse();
    },
  }; */

  //const columnsDef = [actionColDef].concat(columns);
  const columnsDef = columns;
  return (
    <div
      style={{ height: height ? height : "" }}
      className={height ? "" : "h-[calc(100vh-165px)]"}
    >
      <DataGridPro
        rows={rows}
        columns={columnsDef}
        loading={loading}
        checkboxSelection={!!checkboxSelection}
        disableMultipleRowSelection={!!disableMultipleRowSelection}
        rowModesModel={rowModesModel}
        rowHeight={40}
        columnHeaderHeight={36}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        onRowSelectionModelChange={onRowSelectionModelChange}
        onProcessRowUpdateError={console.error}
        components={components}
        componentsProps={componentsProps}
        paginationMode="server"
        classes={{
          // root: "border-none bg-white dark:bg-blue dark:text-white/70 px-3 mt-4",
          columnHeader: "bg-gray-20 bg-[#f8f8f8] dark:bg-white/10 shadow-sm ",
          columnHeaderTitle: "tableHead font-bold text-md capitalize px-0",
          row: "text-md hover:bg-red-500",
        }}
        apiRef={apiRef}
        initialState={{
          pinnedColumns: { left: ["__check__"], right: ["action"] },
        }}
        getRowId={getRowId}
        filterMode={
          filterMode ? filterMode : onFilterModelChange ? "server" : "client"
        }
        onFilterModelChange={onFilterModelChange}
        editMode="row"
        onEditRowModelChange={onEditRowModelChange}
        onCellEditStart={onCellEditStart}
        onCellEditCommit={onCellEditCommit}
        pagination
        paginationModel={
          pagination === "No"
            ? { page: 1, pageSize: rows?.length }
            : {
                page: +paginationModel?.page - 1,
                pageSize: +paginationModel?.pageSize,
              }
        }
        onPaginationModelChange={onPaginationModelChange}
        rowCount={rowCount} // Provide the total count of rows from the API response
        rowsPerPageOptions={rowsPerPageOptions} // Customize the available options
        pageSizeOptions={[25, 50, 75, 100, 250, 500, 1000, 1500, 2000]}
        onPageSizeChange={onPageSizeChange}
        processRowUpdate={processRowUpdate}
        disableColumnMenu
        hideFooter
        columnVisibilityModel={columnVisibilityModel}
      />
      <Dialog
        open={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to delete record?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowDeletePrompt(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              try {
                setShowDeletePrompt(false);
                const response = await api.delete(
                  `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/${rowIdToDelete}`
                );
                if (response.status === 200) {
                  console.log(
                    `Row with ID ${rowIdToDelete} has been deleted successfully.`
                  );

                  // Update local state by filtering out the deleted row
                  // setBacklinksData(
                  //   rows.filter((row) => row.id !== rowIdToDelete)
                  // );
                  fetchBacklinksData();
                  toast.success("Backlink Deleted", { autoClose: 3000 });
                } else {
                  console.log(`Failed to delete row with ID ${rowIdToDelete}.`);
                }
              } catch (error) {
                console.error(
                  `Error deleting row with ID ${rowIdToDelete}: ${error}`
                );
                toast.error(error.response.data.message);
              }
              // setLoading(false);
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import api from "../../../services/api";
import AutoComplete from "../../../components/common/AutoComplete";
import debounce from "lodash/debounce";
const MoneyDomainHeader = ({
  isLoading,
  selectedPayload,
  setSelectedPayload,
  fetchTasksList,
  setTasksList,
  filters,
  handleSearch,
  setFilters,
  user,
}) => {
  const [backlinksTypeList, setBacklinksTypeList] = useState([]);
  const [isDomains, setDomains] = useState([]);
  const [isAutoComLoader, setAutoComLoader] = useState(false);
  const taskStatusOptions = [
    { value: "pending", label: "Pending" },
    { value: "inProcess", label: "In Process" },
    { value: "hold", label: "Hold" },
    { value: "cancelled", label: "Cancelled" },
    { value: "completed", label: "Completed" },
    { value: "inReview", label: "In Review" },
    { value: "reOpen", label: "Reopen" },
  ];

  const getBacklinkTypeList = () => {
    api
      .get(`/api/backlinks/backlink_type_list`)
      .then((res) => setBacklinksTypeList(res.data))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getBacklinkTypeList();
  }, []);

  const handleSelect = (item) => {
    setTasksList([]);
    setSelectedPayload({
      domain_data: {
        domain: item?.domain,
        domainId: item?._id,
      },
      backlink: "",
      task_status: "",
    });
    let newFilters = {
      groupOp: "AND",
      rules: [],
    };
    fetchTasksList({
      filters: newFilters,
      domainId: item?._id,
    });
    setFilters(newFilters);
  };
  const fetchData = useCallback(async (searchQuery) => {
    if (searchQuery?.length === 0) {
      setDomains([]);
      return;
    }
    const payload = {
      user_id: user?.user?.id,
      is_assigned: true,
      sort_field: null,
      sort_by: null,
      filters: {
        groupOp: "AND",
        rules: [
          {
            field: "is_expired",
            op: "eq",
            data: false,
          },
          {
            field: "domain",
            op: "cn",
            data: searchQuery,
          },
        ],
      },
      selected_columns: "domain",
    };
    setAutoComLoader(true);
    try {
      const res = await api.post("/api/domains", payload);
      if (res.status === 200 || res.status === 201) {
        setDomains(
          res?.data?.domainListData?.map((item) => ({
            _id: item["d.id"],
            domain: item["d.domain"],
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
    setAutoComLoader(false);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleQueryChange = useCallback(
    debounce((newQuery) => fetchData(newQuery), 500),
    []
  );
  return (
    <>
      <div className="bg-white my-2 border rounded">
        <div className="flex flex-col md:flex-row items-start px-2 py-2">
          <div className="flex mb-2 md:mb-0">
            <AutoComplete
              data={isDomains}
              idField="_id"
              valueField="domain"
              onSelect={handleSelect}
              onQueryChange={handleQueryChange}
              placeholder="Search domain..."
              setAutoComLoader={setAutoComLoader}
              isAutoComLoader={isAutoComLoader}
              selectedItem={selectedPayload?.domain_data}
              width="w-[250px] "
              height="h-[37px]"
              extraClass="mr-2"
            />
          </div>
          <select
            disabled={isLoading}
            className="inputField w-full md:w-fit py-2 mb-2 md:mb-0 md:mr-2"
            value={selectedPayload?.backlink}
            onChange={(e) => {
              setSelectedPayload({
                ...selectedPayload,
                backlink: e.target.value,
              });
            }}
          >
            <option value="">Select Backlink Type</option>
            {backlinksTypeList.map((item, index) => (
              <option key={index} value={item.id}>
                {item.type}
              </option>
            ))}
          </select>
          <select
            disabled={isLoading}
            className="inputField w-full md:w-fit py-2 mb-2 md:mb-0 md:mr-2"
            value={selectedPayload?.task_status}
            onChange={(e) => {
              setSelectedPayload({
                ...selectedPayload,
                task_status: e.target.value,
              });
            }}
          >
            <option value="">Select Status</option>
            {taskStatusOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <button
            className="btnPrimary w-full md:w-auto mt-2 md:mt-0"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
      </div>
    </>
  );
};

export default MoneyDomainHeader;
